import request from "superagent"
import StorageService from "src/services/storage-service"
import { JWT_STORAGE_KEY } from "./constants"
import { API_PATH } from "./api-routes"
import { INDEX_PAGE } from "./routes"
import { useNavigate } from "react-router-dom"

export class ServiceRequest {

    constructor(appUrl = API_PATH) {
        this.appUrl = appUrl
        this.storageService = new StorageService()
        this.useNavigate = new useNavigate()
        this.timeoutInMillis = 200000
    }

    verifyStatusError(method, path, err) {
        switch (err.status) {
            case 401:
                this.storageService.clearAllStorage()
                window.location.href = `${INDEX_PAGE}?mensagem=Sessão expirada, faça login para continuar`
                break
            case 403:
                this.useNavigate(`/solicitar-acesso`, {
                    state: {
                        erro: JSON.stringify(err),
                        path: path,
                        method: method,
                        status: err.status
                    }
                })
                break
            default:
                throw err
        }
    }

    async post(path, body, noVerifyStatus, authorization = `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`) {
        return request
            .post(`${this.appUrl}${path}`)
            .send(body)
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("Content-Type", "application/json")
            .set("Authorization", authorization)
            .catch((err) => {
                console.log(err)
                if (noVerifyStatus) {
                    throw err
                }
                this.verifyStatusError("POST", path, err)
            })
    }

    async postFormData(path, formData) {
        return request
            .post(`${this.appUrl}${path}`)
            .send(formData)
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("authorization", `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`)
            .catch((err) => {
                this.verifyStatusError("FORM_DATA", path, err)
            })
    }


    async put(path, body) {
        return request
            .put(`${this.appUrl}${path}`)
            .send(body)
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("Content-Type", "application/json")
            .set("authorization", `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`)
            .catch((err) => {
                this.verifyStatusError("PUT", path, err)
            })
    }

    async patch(path, body) {
        return request
            .patch(`${this.appUrl}${path}`)
            .send(body)
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("Content-Type", "application/json")
            .set("authorization", `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`)
            .catch((err) => {
                this.verifyStatusError("PATCH", path, err)
            })
    }


    async get(path, authorization = `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`) {
        return request
            .get(`${this.appUrl}${path}`)
            .set("Content-Type", "application/json")
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("authorization", authorization)
            .then((response) => {
                return response
            })
            .catch((err) => {
                this.verifyStatusError("GET", path, err)
            })
    }

    async getBlob(path, authorization = `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`) {
        return request
            .get(`${this.appUrl}${path}`)
            .responseType('blob')
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("authorization", authorization)
            .then((response) => {
                return response
            })
            .catch((err) => {
                this.verifyStatusError("GET", path, err)
            })
    }


    async getEndpoint(endpoint) {
        return request
            .get(endpoint)
            .set("Content-Type", "application/json")
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("authorization", `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`)
            .then((response) => {
                return response
            })
            .catch((err) => {
                this.verifyStatusError("GET", endpoint, err)
            })
    }

    async delete(path) {
        return request
            .delete(`${this.appUrl}${path}`)
            .timeout({
                response: this.timeoutInMillis,
                deadline: this.timeoutInMillis,
            })
            .set("Content-Type", "application/json")
            .set("authorization", `${this.storageService.getItemLocalStorage(JWT_STORAGE_KEY)}`)
            .catch((err) => {
                this.verifyStatusError("DELETE", path, err)
            })
    }


}