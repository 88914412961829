import { Box, Button, Grid, Tab, Tabs, TextField } from "@mui/material"
import React, { useRef, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import AlertMessage from "src/components/alert-message"
import DatePickerSelect from "src/components/date/date-picker-select"
import CharacteristicsService from "src/services/characteristics-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import ModalDialogPage from "src/components/modal-dialog"
import PriceCharacteristicsGuarantee from "./table-characteristics/price-characteristics-guarantee"
import PriceCharacteristicsEmissao from "./table-characteristics/price-characteristics-emissao"
import PriceMemoryCalc from "./table-memory/price-memory-calc"
import PriceMemoryPUPAR from "./table-memory/price-memory-pupar-calc"
import PriceMemoryPUMTM from "./table-memory/price-memory-pu-mtm-calc"
import SkeletonTable from "src/components/skeleton-table"
import PriceMemoryResultTable from "./table-memory/price-memory-result-table"

const PriceCharacteristics = () => {
    const tablePUPAR = useRef()
    const tableMTM = useRef()
    const tableResultCalc = useRef()

    const tabs = [
        { id: 0, description: "CARACTERÍSTICAS EMISSÃO" },
        { id: 1, description: "MEMÓRIA DE CÁLCULO" },
    ]

    const service = new CharacteristicsService()
    const [selectedTab, setSelectedTab] = useState(0)
    const [cetipIsin, setCetipIsin] = useState(null)
    const [dateSelected, setDateSelected] = useState(null)
    const [memory, setMemory] = useState(false)
    const [openModalError, setOpenModalError] = useState(false)
    const [reportErrorMessage, setReportErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingMemory, setLoadingMemory] = useState(false)
    const [loadingParameters, setLoadingParameters] = useState(false)
    const [loadingMemoryResult, setLoadingMemoryResult] = useState(false)
    const [emissao, setEmissao] = useState(null)
    const [memoriaCalculo, setMemoriaCalculo] = useState(null)
    const [garantia, setGarantia] = useState([])
    const [memoryPUPAR, setMemoryPUPAR] = useState([{}])
    const [memoryResultPrice, setMemoryResultPrice] = useState([{}])
    const [memoryMTM, setMemoryMTM] = useState([{}])
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [versoesGarantias, setVersoesGarantias] = useState([])
    const [showGarantia, setShowGarantia] = useState(null)
    const [titleValue, setTitleValue] = useState(null)
    const [cetipIsinError, setCetipIsinError] = useState(false)

    const changeSelectGarantia = (value) => {
        const changeGarantia = garantia.filter(element => element.VersaoDocumento == value)[0]
        setShowGarantia(changeGarantia)
        setTitleValue(value)
    }

    const selectGarantias = (garantias) => {
        if (garantias.length > 0) {
            const docVersions = garantias.map((item) => {
                return { value: item.VersaoDocumento, description: `Versão do Documento ${item.VersaoDocumento}` }
            })
            setVersoesGarantias(docVersions)
        }
    }

    const setDefaultObjects = () => {
        setMemoryPUPAR([{}])
        setMemoryMTM([{}])
        setMemoryResultPrice([{}])
        setMemoriaCalculo(null)
        setGarantia(null)
        setEmissao(null)
        setShowGarantia(null)
        setTitleValue(null)
        setVersoesGarantias([])
    }

    const loadDataMaisRecente = async (cetipIsin) => {
        if(dateSelected !== null) {
            return dateSelected
        }

        setLoading(true)
        let dataMaisRecente = dayjs().format(DATE_FORMAT)
        return service.getDataMaisRecente(cetipIsin)
            .then((response) => {
                const { body } = response
                const { DataMaisRecente } = body
                dataMaisRecente = dayjs(DataMaisRecente[0]).format(DATE_FORMAT)
                setDateSelected(dataMaisRecente)

                return dataMaisRecente
            })
            .catch((error) => {
                console.log(error)
                return dataMaisRecente
            }).finally(() => {
                setLoading(false)
            })
    }

    const loadGarantias = (cetipIsin) => {
        setLoading(true)
        var promises = [
            service.getGuaranteeCalc(cetipIsin),
        ]

        Promise.all(promises).then((response) => {
            setLoading(false)
            const garantias = response[0]

            setGarantia(garantias.body)
            selectGarantias(garantias.body)
            setTitleValue(garantias.body[0].VersaoDocumento)
            setShowGarantia(garantias.body[0])
        }).catch(() => {
            setLoading(false)
        })
    }

    const loadEndpoints = (dataReferencia) => {
        setLoading(true)
        var promises = [
            service.getCharacteristics(cetipIsin),
        ]

        Promise.all(promises).then((response) => {
            const emissaoCaracteristicas = response[0]

            if (typeof emissaoCaracteristicas.body == 'string') {
                openAlert(emissaoCaracteristicas.body, "warning")
            }

            setEmissao(emissaoCaracteristicas.body[0])
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading(false)
            loadGarantias(cetipIsin)
            loadMemory(cetipIsin, dataReferencia)
        })
    }

    const loadMemory = (isin, data_referencia) => {
        setLoadingParameters(true)
        setLoadingMemoryResult(true)
        setLoadingMemory(true)

        setMemoryResultPrice([])
        setMemoryPUPAR([{}])
        setMemoryMTM([{}])
        setMemoryResultPrice([{}])

        loadParametersCalc(isin, data_referencia)
    }

    const loadResultPrice = (cetipIsin, data_referencia) => {
        setLoadingMemoryResult(true)
        var promises = [
            service.getMemoryCalcResultPrice(cetipIsin, data_referencia),
        ]

        Promise.all(promises).then((response) => {
            const memoryResultPrice = response[0]
            const { body } = memoryResultPrice

            setMemoryResultPrice(body[0])
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoadingMemoryResult(false)
            loadMemoryEndpoints(cetipIsin, data_referencia)
        })
    }

    const loadMemoryEndpoints = (cetipIsin, data_referencia) => {
        setLoadingMemory(true)
        var promises = [
            service.getMemoryCalcPUPAR(cetipIsin, data_referencia),
            service.getMemoryCalcMTM(cetipIsin, data_referencia),
        ]

        Promise.all(promises).then((response) => {
            const memoryPUPAR = response[0]
            const memoryMTM = response[1]

            setMemoryPUPAR(memoryPUPAR.body)
            setMemoryMTM(memoryMTM.body)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoadingMemory(false)
        })
    }

    const loadParametersCalc = (isin, data_referencia) => {
        if (isin === undefined) {
            isin = cetipIsin
        }

        setLoadingParameters(true)
        service.getParametersCalc(isin, data_referencia).then((response) => {
            const { body } = response

            if (typeof body === 'string') {
                openAlert(body, "warning")
                return
            }

            setMemoriaCalculo(body[0])
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoadingParameters(false)
            loadResultPrice(isin, data_referencia)
        })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const handleChange = (_event, newValue) => {
        if (newValue === 1) {
            setMemory(true)
        } else {
            setMemory(false)
        }

        setSelectedTab(newValue)
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <>
                        <PriceCharacteristicsEmissao data={emissao} />
                        <br /><br />
                        {garantia === null || garantia.length === 0 || typeof garantia === "string" ? null : <PriceCharacteristicsGuarantee data={showGarantia} selectTitleList={versoesGarantias} onChangeTitle={changeSelectGarantia} titleValue={titleValue} />}
                    </>
                )
            case 1:
                return (
                    <>
                        {loadingParameters ? <SkeletonTable /> : <PriceMemoryCalc data={memoriaCalculo} />}
                        <br /><br />
                        {loadingMemoryResult ? <SkeletonTable /> : <PriceMemoryResultTable tableRef={tableResultCalc} data={memoryResultPrice} />}
                        <br /><br />
                        {loadingMemory ? <SkeletonTable /> : <PriceMemoryPUPAR tableRef={tablePUPAR} data={memoryPUPAR} />}
                        <br /><br />
                        {loadingMemory ? <SkeletonTable /> : <PriceMemoryPUMTM tableRef={tableMTM} data={memoryMTM} />}
                    </>
                )
            default:
                return null
        }
    }

    const calculate = async () => {
        if (cetipIsin == "" || cetipIsin == null || cetipIsin == undefined) {
            setCetipIsinError(true)
            return
        }

        const dataMaisRecente = await loadDataMaisRecente(cetipIsin)

        loadEndpoints(dataMaisRecente)
        setCetipIsinError(false)
        setDefaultObjects()
    }

    const sendReportError = () => {
        setLoading(true)
        service.postReportError(cetipIsin, reportErrorMessage)
            .then(() => {
                setOpenModalError(false)
                openAlert("Mensagem enviada com sucesso", "success")
            })
            .catch(() => {
                openAlert("Erro ao enviar mensagem, tente novamente", "error")
            }).finally(() => {
                setLoading(false)
            })
    }

    const exportExcel = () => {

        if (selectedTab === 0) {
            service.exportEmissaoExcel(cetipIsin, [{ ...emissao, ...showGarantia }])
        }

        if (selectedTab === 1) {
            service.exportMemoryExcel(cetipIsin, { ...memoryResultPrice, ...memoriaCalculo }, memoryPUPAR, memoryMTM)
        }
    }

    return (
        <LayoutPage title="Produtos" loading={loading}
        >
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />

            <Grid container direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={2}>
                <Grid item xs={12} md={8} xl={8}>
                    <Grid container direction={"row"} alignItems={"center"} justifyContent={"flex-start"} spacing={3}>
                        <Grid item xs={12} md={6} xl={3}>
                            <DemoContainer components={['TextField']}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type={"text"}
                                    error={cetipIsinError}
                                    label="CETIP ou ISIN"
                                    value={cetipIsin}
                                    onChange={(event) => {
                                        if (event.target.value == "") {
                                            setCetipIsinError(true)
                                            setDateSelected(null)
                                            setCetipIsin(event.target.value)
                                        } else {
                                            setCetipIsinError(false)
                                            setDateSelected(null)
                                            setCetipIsin(event.target.value)
                                        }

                                    }}
                                />
                            </DemoContainer>
                        </Grid>
                        {
                            memory ? <Grid item >
                                <DatePickerSelect onChange={(date) => {
                                    const dateString = date.format(DATE_FORMAT)
                                    setDateSelected(dateString)
                                }} value={dateSelected} />
                            </Grid> : null
                        }
                        <Grid item>
                            <Button onClick={calculate} color={"primary"} variant={"contained"}>Consultar</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item> <Button color={"success"} variant={"contained"} onClick={() => { exportExcel() }}>Exportar Excel</Button></Grid>
                        <Grid item> <Button color={"primary"} variant={"contained"} onClick={() => { setReportErrorMessage(null), setOpenModalError(true) }}>Reportar Erro</Button></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column"
                }}>
                <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs">
                    {tabs.map((tab) => (
                        <Tab label={tab.description} key={tab.id} />
                    ))}
                </Tabs>

                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Grid
                        container
                        direction="row"
                        alignContent="flex-start"
                        justifyContent="flex-start"
                        spacing={0}
                        sx={{
                            padding: 2,
                            height: "100%", // altura de 100% da Box
                        }}>
                        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                            {renderContent()}
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <ModalDialogPage open={openModalError}
                title="Reportar Erro"
                close={() => {
                    setOpenModalError(false)
                }}>
                <>
                    <Grid container direction="column">
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                label="Mensagem"
                                variant="outlined"
                                placeholder="Descreva o erro ou inconsistência nas informações que encontrou que nosso time de suporte irá rever!"
                                multiline
                                focused
                                minRows={10}
                                value={reportErrorMessage}
                                onChange={(event) => setReportErrorMessage(event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container direction="column" alignContent={"flex-end"} justifyContent={"flex-end"}>
                        <Grid item xs={12} md={12} xl={12}>
                            <Button color={"primary"} variant={"contained"} onClick={sendReportError}>Reportar Erro</Button>
                        </Grid>
                    </Grid>
                </>
            </ModalDialogPage>
        </LayoutPage>
    )
}

export default PriceCharacteristics
