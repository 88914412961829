import React from 'react'
import { TextField } from "@mui/material"
import PropTypes from 'prop-types'
import { changeFormatedValue } from 'src/utils/format-decimal'

const TextFieldFormatCurrency = (props) => {
    const changeValue = (event) => {
        props.onChange(changeFormatedValue(event.target.value))
    }

    return <TextField
        fullWidth
        InputLabelProps={props.focused ? { shrink: true } : undefined}
        InputProps={{ readOnly: props.readOnly ? props.readOnly : false }}
        variant={props.variant ? props.variant : "standard"}
        label={props.label}
        value={props.value}
        type={props.type ? props.type : "text"}
        onChange={changeValue}
    />
}

TextFieldFormatCurrency.propTypes = {
    label: PropTypes.string,
    variant: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    focused: PropTypes.bool,
    type: PropTypes.string
}

export default TextFieldFormatCurrency