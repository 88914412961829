import { Box, Grid, Tab, Tabs , Button, Autocomplete,TextField} from "@mui/material"
import React, { useState,useEffect } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import AlertMessage from "src/components/alert-message"
import Balanços from "./demo_financeiras"
import EmpresaPage from "./empresa_page"
import OutroDocumentos from "./outros_documentos"
import GrupoEconomico from "./grupo_economico"
import Emissoes from "./emissoes"
import DatalakeService from "src/services/datalake-service"
import { DATALAKE_CONTRAPARTE_PATH  } from "src/utils/api-routes"


const EmpresasPage = () => {
    const tabs = [
        { id: 0, description: "Empresa" },
        { id: 1, description: "Balanços" },
        { id: 2, description: "Emissões" },
        { id: 3, description: "Grupo Econômico" },
        { id: 4, description: "Outros Documentos" },
    ]
    const [data, setData] = useState([])
    const calcService = new DatalakeService()
    const [selectedTab, setSelectedTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [isLoading, setIsLoading] = useState(false)
    
    const endpoint= DATALAKE_CONTRAPARTE_PATH


    
    const loadData = async () => {
        setIsLoading(true)
        calcService.loadEndpoint(endpoint).then((response) => {
            setIsLoading(false)
            const { body } = response
            const { results } = body

            const list = results.map((obj, index) => {
                const formattedCPFCNPJ = obj.CPFCNPJ?.replace(/[^\d]/g, '') 
                const displayString = `${obj.RazaoSocial} - ${obj.CPFCNPJ}` 
                
                return {
                    ...obj,
                    id: index,
                    formattedCPFCNPJ,
                    displayString
                }
            })

            setData(list)
        })
    }

    useEffect(() => {
        loadData()
    }, []) 
    
    const filterAutocompleteOptions = (options, inputValue) => {
        const sanitizedInput = inputValue.replace(/[^\d\w]/g, '').toLowerCase() // Remove caracteres especiais
        return options.filter((option) =>
            option.RazaoSocial.toLowerCase().includes(sanitizedInput) || 
            option.formattedCPFCNPJ.includes(sanitizedInput)
        )
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const handleChange = (_event, newValue) => {
        setSelectedTab(newValue)
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <>
                        < EmpresaPage loading={setLoading} alert={openAlert} />
                    </>
                )
            case 1:
                return (
                    <>
                        < Balanços loading={setLoading} alert={openAlert} />
                    </>
                )
            case 2:
                return (
                    <>
                        < Emissoes loading={setLoading} alert={openAlert} />
                    </>
                )
            case 3:
                return (
                    <>
                        < GrupoEconomico loading={setLoading} alert={openAlert} />
                    </>
                )
            case 4:
                return (
                    <>
                        < OutroDocumentos loading={setLoading} alert={openAlert} />
                    </>
                )
            default:
                return null
        }
    }

    return (
        <LayoutPage title="Empresas" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                <Autocomplete
                    options={data}
                    getOptionLabel={(option) => option.displayString || ''} 
                    filterOptions={(options, { inputValue }) =>
                        filterAutocompleteOptions(options, inputValue)
                    }
                    value={selectedOption}
                    onChange={(event, newValue) => setSelectedOption(newValue)}
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField {...params} label="Razão Social ou CNPJ" variant="outlined" />
                    )}
                    sx={{ width: 500 }}
                />
                </Grid>
                <Grid item>
                    <Button onClick={() => console.log("teste")} sx={{ marginTop: '0px' , height: '56px',backgroundColor:"#222851",color: "#FFFFFF",                        "&:hover": {
                            backgroundColor: "#1A1F3A", 
                        },}}
                        >Consultar Lista Completa
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={() => console.log("teste")} sx={{ marginTop: '0px',  height: '56px',width: '100%', backgroundColor:"#222851",color: "#FFFFFF",                        "&:hover": {
                            backgroundColor: "#1A1F3A", 
                        },}}
                        >Reportar Erro
                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column"
                }}>
                
                <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs">
                    {tabs.map((tab) => (
                        <Tab label={tab.description} key={tab.id} />
                    ))}
                </Tabs>

                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Grid
                        container
                        direction="row"
                        alignContent="flex-start"
                        justifyContent="flex-start"
                        spacing={0}
                        sx={{
                            padding: 2,
                            height: "100%", 
                        }}>
                        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                        {renderContent()}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </LayoutPage>
    )
}

export default EmpresasPage