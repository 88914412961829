import React from "react"
import PropTypes from 'prop-types'
import { Alert, Snackbar } from "@mui/material"

const AlertMessage = (props) => {
    const anchor = {
        vertical: props.vertical ? props.vertical : "top",
        horizontal: props.horizontal ? props.horizontal : "center",
    }
    const { horizontal, vertical } = anchor

    const onClose = () => {
        props.close()
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={props.open}
            onClose={onClose} // Close Snackbar on clicking "x" button
            autoHideDuration={12000}
            message={props.message}
            key={vertical + horizontal}
        >
            <Alert onClose={props.close} severity={props.type || "info"}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

AlertMessage.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
    type: PropTypes.oneOf(["error", "warning", "success", "info"]),
    close: PropTypes.func
}

export default AlertMessage