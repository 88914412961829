import {Button,  Divider, Grid, Link,  TextField, Typography, Box, Autocomplete} from "@mui/material"
import React, {useEffect, useState} from "react"
import RaterService from "src/services/rater-service"
import PropTypes from 'prop-types'
import { RATER_SETORIAL_PATH} from "src/utils/api-routes"
import { TokenService } from "src/services/token-service"
import ParameterTable from "src/components/table/parameter-table"
import EditActionButton from "src/pages/rater/subpages/components/EditButtonRow"
import ModalDialogPage from "src/components/modal-dialog"
import FabButton from "src/components/fab-button"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { formatTimestamp } from "src/utils/format-date"
import TableFormatterService from "./components/tableFormatter"
import { useDropzone } from "react-dropzone"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AlertMessage from "src/components/alert-message"



const RaterClassificacao= (props) => {
    const raterservice = new RaterService()
    const tokenService = new TokenService()
    const tableFormatter = new TableFormatterService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })
    const [userAdmin, setUserAdmin] = useState(false)
    const [openPopUp, setOpenPopUp] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [selectedParams, setSelectedParams] = useState({})
    const endpointSetorial = RATER_SETORIAL_PATH
    const [selectedFile, setSelectedFile] = useState(null)
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [classificacao, setClassificacao] = useState('')
    const [razaosocial, setRazaoSocial] = useState('')
    const [IDCliente, setClienteId] = useState(null) // estado para clienteId
    const [userId, setuserId] = useState(null) // estado para clienteId
        const [alertMessageState, setAlertMessageState] = React.useState({
            open: false,
            message: "",
            type: "info"
        })
    const [cpfcnpjArray, setcpfcnpjArray] = useState([])
    const [razaoSocialArray, setRazaoSocialArray] = useState([])

    

    useEffect(() => {
        loadData()
        const verifyAdmin = async () => {
            try {
                const isAdmin = await tokenService.verifyUserIsAdmin()
                console.log('Retorno de verifyUserIsAdmin:', isAdmin) 
                setUserAdmin(isAdmin)
            } catch (error) {
                console.error('Erro ao verificar se o usuário é admin:', error)
            }
        }

        const jwt = localStorage.getItem("jwt")
        if (jwt) {
            document.cookie = `c2p_jwt=${jwt} domain=.c2ps.com.br path=/`
            const payload = JSON.parse(atob(jwt.split(".")[1])) // decodifica o payload do JWT
            if (payload.user && payload.user.IDCliente) {
                setClienteId(payload.user.IDCliente)
                setuserId(payload.user.ID) 
            }
        }

        verifyAdmin()
    }, [])

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }
    
    const loadData = () => {
        props.loading(true)
        raterservice.listSetorial(endpointSetorial).then((response) => {
            props.loading(false)
    
            const { body } = response
            
            if (body && body.data) {
                const { data, count, next, previous } = body
                const list = data.map((obj, index) => ({ ...obj, id: index }))

                const cpfcnpjArray = Array.from(new Set(list.map((obj) => obj.CPFCNPJ)))
                const razaoSocialArray = Array.from(new Set(
                    list.map((obj) => obj.RazaoSocial).filter(Boolean)
                ))
                setcpfcnpjArray(cpfcnpjArray)
                setRazaoSocialArray(razaoSocialArray)

                setPagination({ count: count, next: next, previous: previous })
                setData(list)
            } else {
                console.error('Dados não encontrados na resposta:', body)
                setPagination({ count: 0, next: null, previous: null })
                setData([])
            }
        }).catch((error) => {
            props.loading(false)
            console.error('Erro ao buscar dados:', error)
            setPagination({ count: 0, next: null, previous: null })
            setData([])
        })
    }
    

    const columns = [
            {
                field: 'id',
                headerName: '#',
                flex: true,
                minWidth: 100, 
                maxWidth: 100, 
            },
            {
                field: 'CPFCNPJ',
                headerName: 'CPFCNPJ',
                flex: 1,
                minWidth: 150,
                maxWidth: 350,
                editable: false
            },
            {
                field: 'RazaoSocial',
                headerName: 'Razão Social',
                flex: 1,
                minWidth: 300,
                maxWidth: 600,
                editable: false
            },
            {
                field: 'ClassificacaoSetorial',
                headerName: 'Classificação Setorial',
                flex: 1,
                minWidth: 100,
                maxWidth: 300,
                editable: false
            },
            {
                field: 'Fonte',
                headerName: 'Fonte',
                flex: 1,
                minWidth: 50,
                maxWidth: 150,
                editable:  true,
                isCellEditable: (params) => params.row.Fonte === 'Usuário',
            },
            {
                field: 'Editar',
                headerName: 'Editar',
                width: 100,
                maxWidth: 150,
                renderCell: (params) =>{

                    return(
                    <EditActionButton params={params} userAdmin={userAdmin} loadData={loadData} cpfcnpjArray={cpfcnpjArray} razaoSocialArray={razaoSocialArray} data={data}/>
                )
                }
                
            },
            {
                field: 'Excluir',
                headerName: 'Excluir',
                width: 100,
                maxWidth: 150,
                renderCell: (params) =>{
                    if (params.row.LinhaID?.startsWith('UserSetorial')) {
                        return (
                            <DeleteRoundedIcon
                                style={{ cursor: 'pointer',color : "#222851" }}
                                onClick={() => handleDeleteClick(params)}
                            />
                        )
                    }
                    return null
                
                }
                
            }
    ]

    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setOpenPopUp(true)
            
        },
        color: "primary",
    }


    const handleDeleteClick = (params) => {
        console.log(params)
        setSelectedParams(params) // Salva os params da linha
        setOpenDialog(true) // Abre o diálogo de confirmação
    }

        const confirmDelete  = () => {
            console.log(selectedParams)
            const LinhaId = selectedParams.row.LinhaID
            const id = LinhaId.split("-")[1]
            raterservice.deleteUser(id).then(() => {
                openAlert("Apagado com sucesso", "success")
                loadData(0, 25)
                setOpenDialog(false)
            }).catch((err) => {
                throw err
            })
        }
    
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        setSelectedFile(file)
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    const handleSave = async () => {
        const trimmedCpfCnpj = cpfCnpj.trim()
        const trimmedClassificacao = classificacao.trim()

        const body = {
            "CPFCNPJ": cpfCnpj,
            "SetorUsuario": classificacao,
            "CriadoPor": userId,
            "IDCliente": IDCliente,
        }

        console.log("Valores do popup:", { trimmedCpfCnpj, trimmedClassificacao })
        console.log("Dados existentes:", data[0])

        const normalize = (value) => value?.toString().trim().toLowerCase()

        // Verificar se o registro já existe
        const exists = data.some((item) => {
            const isCpfCnpjMatch = normalize(item?.CPFCNPJ) === normalize(trimmedCpfCnpj)
            const isClassificacaoMatch =
                normalize(item?.ClassificacaoSetorial) === normalize(trimmedClassificacao)

            return isCpfCnpjMatch && isClassificacaoMatch
        })
        console.log("Resultado da verificação (exists):", exists)

        if (exists) {
            console.log("Já existe um registro com esse CPFCNPJ e Classificação Setorial.")
            setOpenPopUp(false)
            openAlert("Este CPFCNPJ já está associado a essa classificação setorial!","warning")
            
            return // Impede o código de continuar e inserir o valor
        }
    
        // Inserir o novo registro
        return raterservice.createUser(body).then(() => {
            openAlert("Inserido com sucesso", "success")
            loadData(0, 25)
            setOpenPopUp(false)
        }).catch((err) => {
            throw err
        })
    }

    const handleUpload = () => {
        if (selectedFile) {
            let fileType = ""
    
            if (selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                fileType = "xlsx"
            } else if (selectedFile.type === "text/csv") {
                fileType = "csv"
            } else {
                openAlert("Tipo de arquivo não suportado.", "error")
                return
            }
        
        if (selectedFile) {
            raterservice.sendSetorialFile(selectedFile, fileType).then(() => {
                openAlert("Dados inseridos com sucesso.", "success")
                loadData(0, 25)
            }).catch((error) => {
                console.debug(error)
                openAlert("Houve um erro ao enviar o arquivo, tente novamente.", "error")
            })
            .finally(() => {
            })
        }
    }}
 

    const preencherCampos = (campo, valor) => {
        if (!valor) return
        // Buscar o item pelo campo e valor
        const item = data.find(dado => dado[campo] === valor)
        if (item) {
          if (campo === 'CPFCNPJ') {
            setRazaoSocial(item.RazaoSocial || '') 
          } else if (campo === 'RazaoSocial') {
            setCpfCnpj(item.CPFCNPJ || '') 
          }
        } else {
          
          setCpfCnpj('')
          setRazaoSocial('')
        }
    }
  
    return (
        <Grid>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            
            <Grid item xs={12} md={12} sm={12} xl={12}>
                <ParameterTable
                    columns={columns}
                    data={data}
                    pagination={pagination}
                    disableDeleteColumn={true} />
            </Grid>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    Tem certeza que deseja deletar este campo?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="error">
                        Cancelar
                    </Button>
                    <Button onClick={confirmDelete} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <ModalDialogPage
                open={openPopUp}
                title="Adicionar Setor"
                close={() => {
                    setOpenPopUp(false)
                }}
                button={<FabButton action={actionButton} />}
                
                >
                    <Grid container spacing={1} alignItems="flex-start">
                        <Grid item xs={12} sm={2.5}>
                        <Autocomplete
                            value={cpfCnpj}
                            onChange={(event, newValue) => {
                                setCpfCnpj(newValue)
                                preencherCampos('CPFCNPJ', newValue) // Preenche os outros campos com base no CPFCNPJ
                            }}
                            options={cpfcnpjArray}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="CPFCNPJ"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                inputProps={{
                                    ...params.inputProps,
                                    inputMode: 'numeric', // Permite apenas números
                                }}
                                sx={{ width: '100%' }}
                                />
                            )}
                            freeSolo
                            />
                        </Grid>
                        <Grid item xs={12} sm={6.5}>
                        <Autocomplete
                            value={razaosocial}
                            onChange={(event, newValue) => {
                                setRazaoSocial(newValue)
                                preencherCampos('RazaoSocial', newValue) // Preenche os outros campos com base no CPFCNPJ
                            }}
                            options={razaoSocialArray}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Razão Social"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                sx={{
                                    width: '100%'
                                    }}

                                />
                            )}
                            freeSolo
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                            label="Classificação Setorial"
                            variant="outlined"
                            fullWidth
                            value={classificacao}
                            onChange={(e) => setClassificacao(e.target.value)}
                            margin="normal"
                            sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1} display="flex" justifyContent="flex-end"  alignItems="center">
                            <Button     onClick={handleSave} disabled={!cpfCnpj || !razaosocial || !classificacao}
                                sx={{
                                    backgroundColor: !cpfCnpj || !razaosocial || !classificacao ? "#ccc" : "#222851", // Cor desabilitada
                                    color: "#FFFFFF",
                                    fontSize: "0.75rem",
                                    padding: "8px 16px",
                                    minWidth: "80px",
                                    height: "40px",
                                    borderRadius: "8px",
                                    marginTop: "26px",
                                    '&:hover': {
                                        backgroundColor: !cpfCnpj || !razaosocial || !classificacao ? "#ccc" : "#002244",
                                    },
                                }}
                            >
                            Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ display: 'flex' }}>
                        <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Grid item>
                                Caso queira cadastrar em lote, baixe o arquivo de <Link href="/example/ExemploSetorial.xlsx" >Exemplo</Link> e faça o upload abaixo:
                            </Grid>
                            <Grid item xs={12} md={6} xl={6} >
                                <div {...getRootProps({ className: 'dropzone',style: {
                                            border: '2px dashed #222851', 
                                            borderRadius: '8px', 
                                            padding: '20px', 
                                            textAlign: 'center', 
                                            cursor: 'pointer', 
                                            color: '#222851',
                                        }, })} >
                                    <input {...getInputProps()} />
                                    <span>ARRASTE E SOLTE O ARQUIVO AQUI OU CLIQUE PARA SELECIONAR</span>
                                </div>
                            </Grid>
                            {selectedFile ?
                                <Grid item xs={11} md={6} xl={6} sx={{ ml: 'auto', mt: -4 }}>
                                    <Typography variant="subtitle" gutterBottom>Nome: <b>{selectedFile.name.substring(0, 40)}</b></Typography>
                                    <Divider />
                                    <Typography variant="subtitle" gutterBottom>Data de Modificação: <b>{formatTimestamp(selectedFile.lastModified)}</b></Typography>
                                    <Divider />
                                    <Typography variant="subtitle" gutterBottom>Tamanho do Arquivo (MB): <b>{tableFormatter.getFileSize(selectedFile.size)}</b></Typography>
                                    <Divider />
                                    <Typography variant="subtitle" gutterBottom>Tipo: <b>{selectedFile.type}</b></Typography>
                                    <Divider />
                                </Grid>
                                : null}
                        </Grid>
                    </Grid>
                    <Grid item  sx={{ marginTop: '16px' }}>
                        <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
                            <Button
                                onClick={() => {
                                    handleUpload()      
                                    setOpenPopUp(false) 
                                    loadData(0, 25)     
                                }}
                                variant="outlined"
                                sx={{
                                    backgroundColor: selectedFile ? '#222851' : '#B0B0B0', // Fica cinza quando desabilitado
                                    color: selectedFile ? '#fff' : '#888', // Cor do texto fica mais clara quando desabilitado
                                    borderColor: selectedFile ? '#007BFF' : '#B0B0B0', // Cor da borda fica cinza
                                    '&:hover': {
                                        backgroundColor: selectedFile ? "#002244" : '#B0B0B0', // Cor de hover ajustada
                                    },
                                }}
                                disabled={!selectedFile} 
                                >UPLOAD
                            </Button>
                                {selectedFile ?
                                    <Button 
                                                variant="outlined" 
                                                color="error" 
                                                onClick={() => setSelectedFile(null)} 
                                                sx={{ marginTop: '0px' }}
                                            >Remover Arquivo
                                    </Button>
                                : null}
                        </Box>
                    </Grid>
            </ModalDialogPage>
        </Grid>

    )
}

RaterClassificacao.propTypes = {
    loading: PropTypes.func,
}

export default RaterClassificacao