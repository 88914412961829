import dayjs from "dayjs"

export const formatDateUtc = (value, format) => {
    if (value === null) return "N/A"
    return dayjs(value).utc().format(format)
}

export const ordenaPorDataReferencia = (results, type) => results.sort((a, b) => {
    // Convertendo as datas para objetos Date para comparar
    const dateA = new Date(a.DataReferencia)
    const dateB = new Date(b.DataReferencia)

    if (type === "asc") {
        return dateA - dateB
    }

    if (type === "desc") {
        return dateB - dateA
    }
})

export const ordenaPorData = (results, key, type) => results.sort((a, b) => {
    // Convertendo as datas para objetos Date para comparar
    const dateA = new Date(a[key])
    const dateB = new Date(b[key])

    if (type === "asc") {
        return dateA - dateB
    }

    if (type === "desc") {
        return dateB - dateA
    }
})