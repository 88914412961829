import { DATALAKE_FINCS_EVENTOS_PATH } from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"


class EventosFluxoService {
    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async getFincsEventos(query) {
        return this.serviceRequest.get(`${DATALAKE_FINCS_EVENTOS_PATH}${query}`)
    }
}

export default EventosFluxoService