import dayjs from "dayjs"
import { DATE_FORMAT_SHOW, DATE_TIME_FORMAT_SHOW } from "./constants"

export const formatDate = (dateString) => {
    const date = dateString.split("-")
    return `${date[2]}/${date[1]}/${date[0]}`
}

export const formatSlashDate = (dateString) => {
    const date = dateString.split("/")
    return `${date[2]}-${date[1]}-${date[0]}`
}

export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`
    return formattedDate
}

export const formateDateTimeString = (timestamp) => {
    const parsedDate = dayjs(timestamp.split('.')[0])
    
    if(parsedDate.isValid()) {
        return parsedDate.format(DATE_TIME_FORMAT_SHOW)
    } else {
        return ''
    }
}

export const formatDateString = (date) => {
    const parsedDate = dayjs(date)
    
    if(parsedDate.isValid()) {
        return parsedDate.format(DATE_FORMAT_SHOW)
    } else {
        return ''
    }
}

export const formatDateDataGrid = (params) => {
    if (params.value === null) {
        return ""
    }
    return formateDateTimeString(params.value)
}

export const formatInputDate = (value) => {
    // Remove qualquer caractere não numérico
    const digits = value.replace(/\D/g, '')

    // Formata como dd/MM/yyyy
    const day = digits.slice(0, 2)
    const month = digits.slice(2, 4)
    const year = digits.slice(4, 8)

    let formattedDate = day
    if (month) formattedDate += `/${month}`
    if (year) formattedDate += `/${year}`

    return formattedDate
}
