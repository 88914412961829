import { ServiceRequest } from "src/utils/service-request"
import { RATER_SETORIAL_UPDATE_PATH,RATER_USER_SETORIAL_PATH, RATER_SETORIAL_FILE_PATH} from "src/utils/api-routes"


class RaterService {

    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async paginate(endpoint) {
        return await this.serviceRequest.getEndpoint(endpoint)
    }

    async loadEndpoint(endpoint, filter = null) {
        return await this.serviceRequest.get(`${endpoint}?limit=10000&${filter}`)
    }

    async loadPaginationEndpoint(endpoint, filter = null, limit = 25, offset = 0) {
        return await this.serviceRequest.get(`${endpoint}?limit=${limit}&offset=${offset}&${filter}`)
    }

    async createTableEndpoint(endpoint, body) {
        return await this.serviceRequest.post(`${endpoint}`, body)
    }

    async updateTableEndpoint(endpoint, id, body) {
        return await this.serviceRequest.patch(`${endpoint}/${id}`, body)
    }

    async deleteTableEndpoint(endpoint, id) {
        return await this.serviceRequest.delete(`${endpoint}/${id}`)
    }

    async listSetorial(endpoint, filter = null) {
        return await this.serviceRequest.get(`${endpoint}?limit=10000&${filter}`)
    }

    async updateSetorial( id, body) {
        return await this.serviceRequest.patch(`${RATER_SETORIAL_UPDATE_PATH}/${id}`, body)
    }
    
    async createUser( body) {
        return await this.serviceRequest.post(`${RATER_USER_SETORIAL_PATH}`, body)
    }

    async deleteUser(id) {
        return await this.serviceRequest.delete(`${RATER_USER_SETORIAL_PATH}/${id}`)
    }
    
    async sendSetorialFile(file,tipo_arquivo) {
        try {
            const formData = new FormData()

            formData.append('arquivo', file)
            formData.append('tipo_extensao', tipo_arquivo)
            return this.serviceRequest.postFormData(RATER_SETORIAL_FILE_PATH, formData)
        } catch (error) {
            console.error("Erro na leitura do arquivo Excel:", error)
        }
    }


    
}

export default RaterService