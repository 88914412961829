import {Grid} from "@mui/material"
import React from "react"

const OutroDocumentos = () => {
    
    return (
        <Grid>
        </Grid>

    )
}

export default OutroDocumentos