import React from 'react'
import PropTypes from 'prop-types'
import CircleIcon from '@mui/icons-material/Circle'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Chip, Tooltip } from '@mui/material'
import { styled } from "@mui/material/styles"

const RotatingIcon = styled(AutorenewIcon)(() => ({
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
}))

const OnlineStatus = ({ label, loading, status, color, title }) => {

    return (
        <>
            <Tooltip title={title}>
                {loading ?
                    <Chip icon={<RotatingIcon />} variant="outlined" label={"Carregando"} /> :
                    color ? <Chip icon={<CircleIcon />} variant="outlined" color={color} label={label} /> :
                        <Chip icon={<CircleIcon />} variant="outlined" color={status ? "success" : "gray"} label={label} />
                }

            </Tooltip >
        </>
    )
}

OnlineStatus.propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string,
    status: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string
}


export default OnlineStatus