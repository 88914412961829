import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import ParameterTable from "src/components/table/parameter-table"
import DatalakeService from "src/services/datalake-service"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"

const ParameterAliasNomeDevedoresTable = (props) => {
    const calcService = new DatalakeService()
    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        calcService.loadEndpoint(props.endpoint).then((response) => {
            props.loading(false)
            console.log(response)
            const { body } = response
            const { results } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "Alias": "N/A",
            "RazaoSocial": "N/A",
            "CPFCNPJ": "N/A",
        }

        props.loading(true)
        return calcService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, Alias, RazaoSocial, CPFCNPJ } = newRow

        const body = {
            "Alias": Alias,
            "RazaoSocial": RazaoSocial,
            "CPFCNPJ": CPFCNPJ,
        }

        props.loading(true)
        return calcService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        const { ID } = row
        return calcService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            flex: true,
        },
        {
            field: 'Alias',
            headerName: 'Alias',
            flex: 1,
            minWidth: 200,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'RazaoSocial',
            headerName: 'RazaoSocial',
            flex: 1,
            minWidth: 200,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPFCNPJ',
            flex: 1,
            minWidth: 200,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT_SHOW),
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT_SHOW),
        }
    ]

    return (
        <div>
            <ParameterTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                loadData={loadData} />
        </div>
    )
}

ParameterAliasNomeDevedoresTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterAliasNomeDevedoresTable
