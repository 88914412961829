export const removeValoresVaziosPayloadTrocaPorNull = (method, value) => {
    if (method === "post") {
        let newObj = { ...value }
        Object.keys(newObj).forEach((key) => {
            if (newObj[key] === "") {
                newObj[key] = null
            }
        })
        return newObj
    }
    return value
}