import { Box, Grid, Paper, TextField, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React, { useState } from "react"
import theme from "src/utils/theme"
import TextFieldFormatCurrency from "./text-field-format-currency"

const DoubleInput = ({ input, multiline = false, multilineRow = 1, onChange }) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputValues, setInputValues] = useState({
        text: "",
        number: "",
    })

    // Função genérica para alterar os valores
    const handleChange = (type, value) => {
        const updatedValues = { ...inputValues, [type]: value }
        setInputValues(updatedValues)
        onChange && onChange({key: input.value, values: updatedValues})
    }

    return (
        <Box sx={{ position: "relative", width: "100%" }}>
            {/* Label flutuante */}
            <Typography
                sx={{
                    position: "absolute",
                    top: "-12px",
                    left: "10px",
                    backgroundColor: "white",
                    zIndex: 1,
                }}
            >
                <Paper
                    sx={{
                        fontSize: "12px",
                        color: isFocused ? theme.palette.primary.main : "rgba(0, 0, 0, 0.60)",
                        padding: "4px 6px",
                        boxShadow: "none",
                    }}
                >
                    {input.label}
                </Paper>
            </Typography>

            {/* Contêiner com os campos */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    border: `1.2px solid ${isFocused ? theme.palette.primary.main : "rgba(0, 0, 0, 0.15)"}`,
                    borderRadius: "0.2em",
                    padding: "12px",
                    transition: "border-color 0.4s ease",
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            >
                <Grid container alignItems="flex-end" spacing={2}>
                    {/* Campo de texto */}
                    <Grid item xs={6}>
                        <TextField
                            multiline={multiline}
                            rows={multilineRow}
                            fullWidth
                            value={inputValues.text}
                            required={input.required}
                            onChange={(event) => handleChange("text", event.target.value)}
                            variant="standard"
                        />
                    </Grid>

                    {/* Campo de número formatado */}
                    <Grid item xs={6}>
                        <TextFieldFormatCurrency
                            value={inputValues.number}
                            onChange={(value) => handleChange("number", value)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

DoubleInput.propTypes = {
    input: PropTypes.shape({
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
        value: PropTypes.string.isRequired,
    }).isRequired,
    multiline: PropTypes.bool,
    multilineRow: PropTypes.number,
    onChange: PropTypes.func,
}

export default DoubleInput