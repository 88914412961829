import React, { useEffect, useState } from "react"
import theme from "src/utils/theme"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"
import { FormControl, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"


export const puMtmHeadItems = [
    'Data Evento',
    'Juros Capitalização',
    'Tipo Juros',
    '%Amortização Ordinária',
    '%Amortização Extraordinária',
    '%Correção Indicador',
    '%Juros',
    '%Juros Incorporado',
]

const selectStyle = {
    border: 'none',
    color: '#fff',
    '& .MuiSelect-icon': {
        color: '#fff', // cor da seta do Select
    },
    '&:hover': {
        borderBottom: 'none', // remove a borda ao passar o mouse
    },
    '&:focus': {
        borderBottom: 'none', // remove a borda ao focar
    },
    '& .MuiSelect-root': {
        color: 'white', // cor do texto dentro do Select
    },
}

const TableEventosFluxo = ({ data }) => {
    const [eventos, setEventos] = useState([])
    const [fluxoBaseList, setFluxoBaseList] = useState([{
        value: "", description: ""
    }])
    const [fluxoBaseSelected, setFluxoBaseSelected] = useState(null)

    useEffect(() => {
        if (data !== null) loadData()
    }, { data })

    const tableHeadItem = (title, key) => <TableCell key={key} align="center" sx={{ color: "#fff", fontWeight: 'bold', border: '1px solid black', backgroundColor: theme.palette.primary.main, whiteSpace: 'pre-wrap' }}>{title}</TableCell>
    const tableItem = (value) => <TableCell align="center" sx={{ border: '1px solid black' }}>{value}</TableCell>

    const getDate = (value) => {
        if (value != null) {
            return dayjs(value).format(DATE_FORMAT_SHOW)
        }
    }

    const loadData = () => {
        const list = Object.entries(data).map(([fluxoBaseKey, data]) => ({
            value: fluxoBaseKey,
            description: data.description
        }))
        setFluxoBaseList(list)

        if (list.length > 0) {
            changeFluxoBase(list[list.length - 1])
        }
    }

    const changeFluxoBase = (fluxoSelecionado) => {
        if (typeof fluxoSelecionado === "string") {
            fluxoSelecionado = fluxoBaseList.filter((item) => item.value === fluxoSelecionado)[0]
            console.log(fluxoSelecionado)
        }

        const { value } = fluxoSelecionado
        const eventosSelecionados = data[value].data

        setEventos(eventosSelecionados)
        setFluxoBaseSelected(fluxoSelecionado)
        setFluxoBaseSelected(value)
    }

    return (
        <Paper>
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ backgroundColor: theme.palette.primary.main, color: '#fff', textAlign: 'center' }}>
                <Grid item>
                    <Typography variant="h6">
                        Eventos do Fluxo
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid item textAlign="center">
                        <FormControl fullWidth>
                            <Select
                                displayEmpty
                                id="select"
                                inputProps={{
                                    'aria-label': 'Without label',
                                }}
                                sx={selectStyle}
                                onChange={(event) => changeFluxoBase(event.target.value)}
                                value={fluxoBaseSelected} // aqui você deve passar a função para tratar a mudança
                            >
                                {fluxoBaseList.map((select, index) => <MenuItem key={index} value={select.value}><b>{select.description}</b></MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <TableContainer sx={{
                maxHeight: 800,
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '10px',
                },
            }}>
                <Table stickyHeader>
                    <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                        <TableRow>
                            {puMtmHeadItems.map((item, index) => tableHeadItem(item, `headTable${index}`))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventos.map((item, index) => <TableRow key={`table_eventos_fluxo_${index}`}>
                            {tableItem(getDate(item.data_evento))}
                            {tableItem(item.juros_capitalizacao)}
                            {tableItem(item.juros_tipo)}
                            {tableItem(formatStringToFloatDecimalCase(item.amortizacao_ordinaria))}
                            {tableItem(formatStringToFloatDecimalCase(item.amortizacao_extraordinaria))}
                            {tableItem(formatStringToFloatDecimalCase(item.correcao_percentual))}
                            {tableItem(formatStringToFloatDecimalCase(item.taxa_accrual))}
                            {tableItem(formatStringToFloatDecimalCase(item.juros_incorporacao_percentual))}
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

TableEventosFluxo.propTypes = {
    data: PropTypes.array
}

export default TableEventosFluxo