import { Button, Grid, Paper, TextField, Tooltip } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import SelectValue from "src/components/select-value"
import ToggleButtonSelect from "src/components/toggle-button-select"
import EditableInput from "../components/editableInput"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import PropTypes from 'prop-types'
import SimuladorService from "src/services/simulador-service"
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW, DATE_TIME_HOUR_MINUTE_FORMAT_SHOW } from "src/utils/constants"
import { changeFormatedValue, formatDecimalCaseReplaceDot, formatStringToFloatDecimalCase, formatToFloatDecimalCase } from "src/utils/format-decimal"
import TextFieldFormatCurrency from "src/components/text-field-format-currency"
import EditableNumberInput from "../components/editableNumberInput"
import SkeletonTable from "src/components/skeleton-table"

const SimuladorSubordinacaoSimulacao = (props) => {
    const percentilDefaultValue = 99
    const plTotalDefaultValue = 0
    const caixaDefaultValue = 0
    const bandaDefaultValue = 0
    const multiplicadorDefaultValue = 1

    const service = new SimuladorService()
    const [loadingTable, setLoadingTable] = useState(false)
    const [selectValue, setSelectValue] = useState({})
    const [selectItemList, setSelectItemList] = useState([])
    const [dataReferencia, setDataReferencia] = useState({})
    const [volumeFinanceiro, setVolumeFinanceiro] = useState("")
    const [qtdOperacoes, setQtdOperacoes] = useState("")
    const [pddObservada, setPddObservada] = useState("")
    const [pddSugerida, setPddSugerida] = useState("")
    const [plTotal, setPLTotal] = useState(plTotalDefaultValue)
    const [nomeCarteira, setNomeCarteira] = useState("")
    const [multiplicador, setMultiplicador] = useState(multiplicadorDefaultValue)
    const [banda, setBanda] = useState(bandaDefaultValue)
    const [percentil, setPercentil] = useState(percentilDefaultValue)
    const [caixa, setCaixa] = useState(caixaDefaultValue)
    const [tableKey, setTableKey] = useState(Math.random())
    const [plInput, setPlInput] = useState([])
    const [bodySimulacao, setBodySimulacao] = useState([])
    const [typeSimuladorTaxaAccrual, setTypeSimuladorTaxaAccrual] = useState(false)
    const subordinationInitialState = [{
        id: 1,
        ID: 1,
        IDCarteiraLote_id: "",
        IDOperacao: "",
        DataReferencia: { value: "", show: "" },
        NomeCota: "Subordinação 1",
        PLCota: 0,
        Caixa: 0,
        OrdemPL: 1,
        Percentil: 0,
        IndexadorCota: "",
        TaxaAccrualCota: 0,
        DurationCota: 0,
        SubordinacaoContabilCota: null,
        ScoreRatingCota: 0,
        TaxaMTMCota: 0,
        SpreadMTMCota: 0,
        PDCota: 0,
        LGDCota: 0,
        EADCota: 0,
        PerdaEsperada: 0,
        CreditVaR: 0,
        CreditTailVaR: 0,
        CriadoEm: ""
    }]
    const [subordinationsState, setSubordinationsState] = useState(subordinationInitialState)

    useEffect(() => {
        loadContent()
    }, [])

    const loadContent = () => {
        props.setLoading(true)
        service.getSelectSimulacao()
            .then((response) => {
                const { body } = response
                const selectList = body.map((item) => ({ value: item, description: `${item.Nome} - ${dayjs(item.CriadoEm).format(DATE_TIME_HOUR_MINUTE_FORMAT_SHOW)}` }))
                setSelectItemList(selectList)
            })
            .catch(() => {
            }).finally(() => {
                props.setLoading(false)
            })
    }

    const setSelect = (value) => {
        props.setLoading(true)
        setLoadingTable(true)
        const { uuid, Nome } = value

        service.loadLoteSelecionado(uuid)
            .then((response) => {
                const { body } = response
                const { union_data } = body
                const { dados_carteira_entrada_original } = body
                const { DataReferenciaNovo, VolumeFinanceiroNovo, QuantidadeOperacoesNovo, PDDManutencao, PDDObservada } = union_data[0]

                if (dados_carteira_entrada_original.length > 0) {
                    const newSubordinationState = dados_carteira_entrada_original
                        .map((item, index) => ({ ...item, id: index + 1, PLCota: formatDecimalCaseReplaceDot(item.PLCota) }))
                        .sort((a, b) => a.OrdemPL - b.OrdemPL)
                    const plInputList = newSubordinationState.map((item) => ({ id: item.id, PLCota: item.PLCota }))

                    const { Caixa, Percentil } = dados_carteira_entrada_original[0]

                    setCaixa(formatStringToFloatDecimalCase(Caixa))
                    setPercentil(Percentil)
                    setPlInput(plInputList)
                    setSubordinationsState(newSubordinationState)
                    sumPlTotal(newSubordinationState)
                } else {
                    console.log("cai no else")
                    setPLTotal(0)
                    setPlInput([])
                    setSubordinationsState(subordinationInitialState)
                }

                setSelectValue(value)
                setNomeCarteira(Nome)
                setDataReferencia({ value: DataReferenciaNovo, show: dayjs(DataReferenciaNovo).format(DATE_FORMAT_SHOW) })
                setVolumeFinanceiro(changeFormatedValue(formatStringToFloatDecimalCase(VolumeFinanceiroNovo)))
                setQtdOperacoes(QuantidadeOperacoesNovo)
                setPddObservada(changeFormatedValue(formatStringToFloatDecimalCase(PDDObservada)))
                setPddSugerida(changeFormatedValue(formatStringToFloatDecimalCase(PDDManutencao)))
            })
            .catch(() => {
            }).finally(() => {
                setLoadingTable(false)
                props.setLoading(false)
            })


        setSelectValue(value)
        if (value === "") return
    }

    const sumPlTotal = (list) => {
        const plCotaArray = list.map((item) => formatToFloatDecimalCase(item.PLCota))
        const sum = plCotaArray.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
        const sumFormatted = changeFormatedValue(sum.toFixed(2))

        if (isNaN(sum)) return
        setPLTotal(sumFormatted)
        setPLTotal(sumFormatted)
    }

    const addSubordinacao = () => {
        sumPlTotal(subordinationsState)

        setSubordinationsState((prevState) => {
            const lastOrdemPL = prevState[prevState.length - 1].OrdemPL
            const nextOrdemPL = lastOrdemPL + 1

            return [
                ...prevState,
                {
                    id: nextOrdemPL,
                    ID: nextOrdemPL,
                    IDCarteiraLote_id: "",
                    IDOperacao: "",
                    DataReferencia: "",
                    NomeCota: `Subordinação ${nextOrdemPL}`,
                    PLCota: 0,
                    Caixa: 0,
                    OrdemPL: nextOrdemPL,
                    Percentil: 0,
                    IndexadorCota: "",
                    TaxaAccrualCota: 0,
                    DurationCota: 0,
                    SubordinacaoContabilCota: null,
                    ScoreRatingCota: 0,
                    TaxaMTMCota: 0,
                    SpreadMTMCota: 0,
                    PDCota: 0,
                    LGDCota: 0,
                    EADCota: 0,
                    PerdaEsperada: 0,
                    CreditVaR: 0,
                    CreditTailVaR: 0,
                    CriadoEm: ""
                }
            ]
        })
    }

    const cleanSubordinations = () => {
        setSubordinationsState(subordinationInitialState)
        setPlInput([])
        setPercentil(percentilDefaultValue)
        setCaixa(caixaDefaultValue)
        setMultiplicador(multiplicadorDefaultValue)
        setBanda(bandaDefaultValue)
        setPLTotal(plTotalDefaultValue)
        setPercentil(percentilDefaultValue)
        setTableKey(Math.random())
    }

    const handleSubordinationChange = (field, id, value) => {
        let valueFormatted = formatDecimalCaseReplaceDot(value)

        if (field == 'PLCota') {
            const editPlInputList = plInput.filter(input => input.id !== id)
            const plInputList = [...editPlInputList, { id: id, PLCota: valueFormatted }]
            setPlInput(plInputList)
            sumPlTotal(plInputList)
        }

        if (field == 'OrdemPL') {
            valueFormatted = parseInt(valueFormatted)
        }

        setSubordinationsState(prevState => prevState.map(subordination => {
            return subordination.id === id ? { ...subordination, [field]: valueFormatted } : subordination
        }))
    }

    const validaTaxaDecrescente = () => {
        let taxaValida = true
        if (typeSimuladorTaxaAccrual) {
            subordinationsState.forEach((item, index, array) => {
                if (index > 0) {
                    const valorAtual = item.TaxaAccrualCota
                    const valorAnterior = array[index - 1].TaxaAccrualCota

                    if (valorAtual > valorAnterior) {
                        taxaValida = false
                    }
                }
            })
        }

        return taxaValida
    }

    const handleRemoveSubordination = (id) => {
        if (subordinationsState.length < 2) return
        const list = subordinationsState.filter(item => item.id !== id)
        const editPlInputList = plInput.filter(input => input.id !== id)
        setSubordinationsState(list)
        sumPlTotal(editPlInputList)
        setPlInput(editPlInputList)
    }

    const validaForm = () => {
        if (nomeCarteira.length === 0 || nomeCarteira === null || nomeCarteira === undefined) {
            props.openAlert("Necessário informar o nome da Carteira", "warning")
            return false
        }

        if (!percentil || percentil === null || percentil === undefined) {
            props.openAlert("Necessário informar o Percentil", "warning")
            return false
        }

        if (!caixa || caixa === null || caixa === undefined || caixa === '') {
            props.openAlert("Necessário informar a Caixa", "warning")
            return false
        }

        if (typeSimuladorTaxaAccrual) {
            if (!banda || banda === null || banda === undefined) {
                props.openAlert("Necessário informar a Banda", "warning")
                return false
            }

            if (!multiplicador || multiplicador === null || multiplicador === undefined) {
                props.openAlert("Necessário informar o Multiplicador", "warning")
                return false
            }
        }

        return true
    }

    const postSimulacao = () => {
        if (selectValue === undefined || selectValue === "") {
            return props.openAlert("Selecione um lote de operações para realizar a simulação", "warning")
        }

        if (!validaForm()) {
            return
        }

        if (plTotal <= 0) {
            return props.openAlert("Informe PL de Cotas maiores que zero", "warning")
        }

        const hasDuplicates = subordinationsState.some((item, index, self) =>
            self.findIndex(i => i.OrdemPL === item.OrdemPL) !== index
        )
        if (hasDuplicates) {
            return props.openAlert("Há valores duplicados em OrdemCota", "warning")
        }

        const taxaValida = validaTaxaDecrescente()
        if (!taxaValida) {
            console.log("taxa invalida")
            return props.openAlert("Não tem solução para o cenário solicitado. As taxas devem ser em ordem decrescente entre as cotas de maior e menor subordinação.", "warning")
        }

        const tipoSimulacao = typeSimuladorTaxaAccrual ? "taxa" : "subordinacao"

        props.setLoading(true)
        service.postSimulacao(
            selectValue.uuid,
            nomeCarteira,
            dataReferencia.value,
            caixa,
            percentil,
            plTotal,
            multiplicador,
            tipoSimulacao,
            qtdOperacoes,
            banda,
            subordinationsState
        ).then((response) => {
            const { body } = response
            const { nome_carteira, caixa, percentil, result } = body

            setNomeCarteira(nome_carteira)
            setCaixa(formatStringToFloatDecimalCase(caixa))
            setPercentil(percentil)

            if (result.length > 0) {
                setBodySimulacao(result)
                const newSubordinationState = result
                    .map((item) => ({ ...item, id: item.OrdemCota, PLCota: formatDecimalCaseReplaceDot(item.PLCota), OrdemPL: item.OrdemCota }))
                    .sort((a, b) => parseInt(a.OrdemCota) - parseInt(b.OrdemCota))
                setSubordinationsState(newSubordinationState)
                setTableKey(Math.random())

                const plInputList = newSubordinationState.map((item) => ({ id: item.id, PLCota: item.PLCota }))
                setPlInput(plInputList)
                sumPlTotal(newSubordinationState)
            }
        }).catch((err) => {
            try {
                const json = JSON.stringify(err)
                const { response } = JSON.parse(json)
                const { body } = response
                const { error } = body
                props.openAlert(error, "error")
            } catch (error) {
                props.openAlert("Erro ao realizar a simulacão", "error")
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            props.setLoading(false)
        })
    }

    const saveSimulation = () => {
        if (selectValue === undefined || selectValue === "") {
            return props.openAlert("Selecione um lote de operações para realizar a simulação", "warning")
        }

        if (plTotal <= 0) {
            return props.openAlert("Informe PL de Cotas maiores que zero", "warning")
        }

        props.setLoading(true)
        service.saveSimulation(
            selectValue.uuid,
            nomeCarteira,
            dataReferencia,
            caixa,
            percentil,
            plTotal,
            bodySimulacao,
        ).then((response) => {
            const { body } = response
            console.log(body)
            props.openAlert("Simulação salva com sucesso", "success")
        }).catch(() => {
            props.openAlert("Houve um erro ao salvar a simulação, tente novamente", "error")
        }).finally(() => {
            props.setLoading(false)
        })
    }

    const getGrayClass = () => {
        if (typeSimuladorTaxaAccrual) {
            return "gray-cell"
        }

        return ""
    }

    return <>
        <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4} >
            <Grid item sm={12} md={6} xl={3} xs={12}>
                <SelectValue
                    label={"Selecionar"}
                    value={selectValue}
                    id="select"
                    changeValue={setSelect}
                    options={selectItemList} />
            </Grid>
            <Grid item sm={12} md={6} xl={4} xs={12}>
                <ToggleButtonSelect leftLabel={"Simulação por Índice Subordinação"} rightLabel={"Taxa Accrual"} value={typeSimuladorTaxaAccrual} onChange={setTypeSimuladorTaxaAccrual} />
            </Grid>
        </Grid>
        <br /><br />

        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
            <Grid item sm={12} md={6} xl={2} xs={12}>
                <TextFieldFormatCurrency label={"Data Referência"} value={dataReferencia.show} onChange={setDataReferencia} variant="outlined" readOnly={true} focused={true} />
            </Grid>
            <Grid item sm={12} md={6} xl={2} xs={12}>
                <TextFieldFormatCurrency label={"Volume Financeiro"} value={volumeFinanceiro} onChange={setVolumeFinanceiro} variant="outlined" readOnly={true} focused={true} />
            </Grid>
            <Grid item sm={12} md={6} xl={2} xs={12}>
                <TextFieldFormatCurrency label={"PDD Observada"} value={pddObservada} onChange={setPddObservada} variant="outlined" readOnly={true} focused={true} />
            </Grid>
            <Grid item sm={12} md={6} xl={2} xs={12}>
                <TextFieldFormatCurrency label={"PDD Sugerida"} value={pddSugerida} onChange={setPddSugerida} variant="outlined" readOnly={true} focused={true} />
            </Grid>
            <Grid item sm={12} md={6} xl={2} xs={12}>
                <TextFieldFormatCurrency label={"Quantidade Operações"} value={qtdOperacoes} onChange={setQtdOperacoes} variant="outlined" readOnly={true} focused={true} />
            </Grid>
            <Grid item sm={12} md={6} xl={2} xs={12}>
                <TextFieldFormatCurrency label={"PL Total"} value={plTotal} onChange={setPLTotal} variant="outlined" readOnly={true} focused={true} />
            </Grid>
        </Grid>
        <br /><br />

        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
            <Grid item sm={12} md={4} xl={4} xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={nomeCarteira}
                    onChange={(event) => setNomeCarteira(event.target.value)}
                    label="Nome da Carteira"
                    variant="standard" />
            </Grid>
            {
                typeSimuladorTaxaAccrual ?
                    <>
                        <Grid item sm={12} md={2} xl={2} xs={12}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={banda}
                                onChange={(event) => setBanda(event.target.value)}
                                label="Banda"
                                type={"number"}
                                variant="standard" />
                        </Grid>
                        <Grid item sm={12} md={2} xl={2} xs={12}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={multiplicador}
                                onChange={(event) => setMultiplicador(event.target.value)}
                                label="Multiplicador"
                                type={"number"}
                                variant="standard" />
                        </Grid> </> : null
            }
            <Grid item sm={12} md={2} xl={typeSimuladorTaxaAccrual ? 2 : 4} xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={percentil}
                    onChange={(event) => setPercentil(event.target.value)}
                    label="Percentil"
                    type={"number"}
                    variant="standard" />
            </Grid>
            <Grid item sm={12} md={2} xl={typeSimuladorTaxaAccrual ? 2 : 4} xs={12}>
                <TextFieldFormatCurrency label={"Caixa"} value={caixa} onChange={setCaixa} focused={true} />
            </Grid>
        </Grid>

        <br /><br />

        {loadingTable ? <SkeletonTable /> :
            <>
                <Paper>
                    <DataGrid
                        key={tableKey}
                        autoHeight
                        autoPageSize={false}
                        showColumnVerticalBorder
                        showCellVerticalBorder
                        hideFooter
                        rows={subordinationsState}
                        columns={[
                            {
                                field: 'OrdemPL',
                                headerName: 'Ordem Cota',
                                align: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: true,
                                minWidth: 100,
                                headerAlign: "center",
                                renderCell: ({ id, value }) => <EditableInput
                                    editable={true}
                                    fieldValue={value}
                                    handleChange={(cellValue) => handleSubordinationChange('OrdemPL', id, cellValue)}
                                />,
                            },
                            {
                                field: 'NomeCota',
                                headerName: 'Nome da Cota',
                                align: "center",
                                headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: true,
                                minWidth: 180,
                                renderCell: ({ id, value }) => <EditableInput
                                    editable={true}
                                    fieldValue={value}
                                    handleChange={(cellValue) => handleSubordinationChange('NomeCota', id, cellValue)}
                                />,
                            },
                            {
                                field: 'PLCota',
                                headerName: 'PL da Cota',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                type: 'number',
                                flex: 1,
                                cellClassName: getGrayClass,
                                minWidth: 180,
                                renderCell: ({ id, value }) => <EditableNumberInput
                                    editable={!typeSimuladorTaxaAccrual}
                                    fieldValue={formatStringToFloatDecimalCase(value)}
                                    handleChange={(cellValue) => handleSubordinationChange('PLCota', id, cellValue)}
                                />,
                            },
                            {
                                field: 'IndexadorCota',
                                headerName: 'Indexador',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                renderCell: ({ id, value }) => <EditableInput
                                    editable={true}
                                    fieldValue={value}
                                    handleChange={(cellValue) => handleSubordinationChange('IndexadorCota', id, cellValue)}
                                />,
                            },
                            {
                                field: 'TaxaAccrualCota',
                                headerName: 'TaxaAccrual',
                                align: "center",
                                headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: () => {
                                    if (!typeSimuladorTaxaAccrual) {
                                        return "gray-cell"
                                    } else return ""
                                },
                                renderCell: ({ id, value }) => <EditableNumberInput
                                    editable={typeSimuladorTaxaAccrual}
                                    fieldValue={formatStringToFloatDecimalCase(value)}
                                    handleChange={(cellValue) => handleSubordinationChange('TaxaAccrualCota', id, cellValue)}
                                />,
                            },
                            {
                                field: 'DurationCota',
                                headerName: 'DurationCota',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                type: 'number',
                                minWidth: 180,
                                renderCell: ({ id, value }) => <EditableInput
                                    editable={true}
                                    fieldValue={value}
                                    handleChange={(cellValue) => handleSubordinationChange('DurationCota', id, cellValue)}
                                />,
                            },
                            {
                                field: 'SubordinacaoContabilCota',
                                headerName: 'Subordinação',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'RatingCarteira',
                                headerName: 'Rating',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                            },
                            {
                                field: 'TaxaMTMCota',
                                headerName: 'Taxa MTM',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'SpreadMTMCota',
                                headerName: 'Spread',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'PDCota',
                                headerName: 'PD',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'LGDCota',
                                headerName: 'LGD',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'EADCota',
                                headerName: 'EAD',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'PerdaEsperada',
                                headerName: 'Perda Esperada',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'CreditVaRCota',
                                headerName: 'Credit VaR',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: 'CreditTailVaRCota',
                                headerName: 'Credit Tail VaR',
                                align: "center", headerAlign: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                minWidth: 180,
                                cellClassName: 'gray-cell',
                                renderCell: (params) => formatStringToFloatDecimalCase(params.value)
                            },
                            {
                                field: '',
                                headerName: 'Excluir',
                                align: "center", headerAlign: "center",
                                minWidth: 80,
                                renderCell: (params) => (
                                    <Button onClick={() => handleRemoveSubordination(params.row.id)} color="error" align="center">
                                        <DeleteOutlineOutlinedIcon />
                                    </Button>
                                ),
                            }
                        ]}
                        disableRowSelectionOnClick
                    />
                </Paper>

                <br />
                <Grid container direction={"row"} alignContent={"center"} justifyContent={"center"}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            <Grid container spacing={1} justifyContent={"flex-start"}>
                                <Grid item>
                                    <Button onClick={addSubordinacao} variant="contained">
                                        <Tooltip title="Adicione as cotas de subordinação da sua carteira, quantas forem necessárias">
                                            + Subordinação
                                        </Tooltip>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent={"flex-end"}>
                                <Grid item>
                                    <Button variant="contained" onClick={cleanSubordinations}>Limpar</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={() => {
                                        postSimulacao()
                                    }}>Simular</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={() => {
                                        saveSimulation()
                                    }}>Salvar</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </>
        }
    </>
}


SimuladorSubordinacaoSimulacao.propTypes = {
    setLoading: PropTypes.func,
    openAlert: PropTypes.func,
}

export default SimuladorSubordinacaoSimulacao