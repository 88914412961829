import React, {useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/EditRounded'
import ModalDialog from 'src/components/modal-dialog.js' 
import ModalDialogTitle from 'src/components/modal-dialog.js' 
import DialogContent from '@mui/material/DialogContent'
import RaterService from "src/services/rater-service"
import AlertMessage from "src/components/alert-message"
import {Button,Grid, TextField, Typography, Autocomplete} from "@mui/material"

    

const EditActionButton = ({ params, userAdmin, loadData, cpfcnpjArray,razaoSocialArray, data }) => {
    const { CPFCNPJ, ClassificacaoSetorial, LinhaID, RazaoSocial } = params.row
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [cpfCnpj, setCpfCnpj] = useState(CPFCNPJ)
    const [razaoSocial, setRazaoSocial] = useState(RazaoSocial || '')
    const [classificacaoSetorial, setClassificacaoSetorial] = useState(ClassificacaoSetorial)
    const raterservice = new RaterService()
    const canEdit = userAdmin || (LinhaID && LinhaID.startsWith('UserSetorial'))
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })
 

    useEffect(() => {
         setCpfCnpj(CPFCNPJ) 
         setRazaoSocial(RazaoSocial || '') 
         setClassificacaoSetorial(ClassificacaoSetorial) 
    }, [CPFCNPJ, ClassificacaoSetorial, LinhaID, RazaoSocial])

    const handleEditClick = () => {

        if (canEdit) setOpenModal(true)
    }
    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }
    
    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const handleSaveChanges = async () => {

        
        if (cpfCnpj === CPFCNPJ && classificacaoSetorial === ClassificacaoSetorial && (!razaoSocial || razaoSocial === RazaoSocial)) {
            openAlert('Nenhuma alteração detectada nos valores.',"warning")
            return
        }

        const id = LinhaID.split("-")[1]
        
        const body = {
            "LinhaID": LinhaID,
            "CPFCNPJ": cpfCnpj,
            "ClassificacaoSetorial": classificacaoSetorial,
            "RazaoSocial": razaoSocial === '' ? 'null' : razaoSocial
        }
        

        return raterservice.updateSetorial(id , body).then(() => {
            setIsLoading(true)
            loadData()
            setIsLoading(false)
            setOpenModal(false)
        }).catch((err) => {
            throw err
        })
        

    }

    
    const preencherCampos = (campo, valor) => {
        if (!valor) return
        // Buscar o item pelo campo e valor
        const item = data.find(dado => dado[campo] === valor)
        if (item) {
          if (campo === 'CPFCNPJ') {
            setRazaoSocial(item.RazaoSocial || '') 
          } else if (campo === 'RazaoSocial') {
            setCpfCnpj(item.CPFCNPJ || '') 
          }
        } else {
          
          setCpfCnpj('')
          setRazaoSocial('')
        }
    }

    return canEdit ? (
        
        <>
            <Tooltip title="Editar">
                <IconButton onClick={handleEditClick}>
                    {isLoading ? <CircularProgress size={24} /> : <EditIcon />}
                </IconButton>
            </Tooltip>

            <ModalDialog
                
                open={openModal}
                close={() => setOpenModal(false)}
                title="Editar"
            >            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            
                <ModalDialogTitle onClose={() => setOpenModal(false)} titleSx={{ fontWeight: 'bold' }}>
                    <Typography>
                        Editar Informações de {CPFCNPJ}
                    </Typography>
                </ModalDialogTitle>

                <DialogContent dividers>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Autocomplete
                            value={cpfCnpj}
                            onChange={(event, newValue) => {
                                setCpfCnpj(newValue)
                                preencherCampos('CPFCNPJ', newValue) // Preenche os outros campos com base no CPFCNPJ
                            }}
                            options={cpfcnpjArray}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="CPFCNPJ"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginBottom: 0 }}
                                    inputProps={{
                                        ...params.inputProps,
                                        inputMode: 'numeric', // Permite apenas números
                                    }}
                                />
                            )}
                            freeSolo
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Classificação Setorial"
                            value={classificacaoSetorial}
                            onChange={(e) => setClassificacaoSetorial(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: 0 }}
                        />
                    </Grid>
                    {razaoSocial?.trim() && (
                        <Grid item>
                            <Autocomplete
                                value={razaoSocial}
                                onChange={(event, newValue) => {
                                    setRazaoSocial(newValue)
                                    preencherCampos('RazaoSocial', newValue) // Preenche os outros campos com base no CPFCNPJ
                                }}
                                options={razaoSocialArray}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Razão Social"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ marginBottom: 1 }}
                                    />
                                )}
                                freeSolo
                            />
                        </Grid>
                    )}
                </Grid>
                    <Button onClick={handleSaveChanges} variant="contained" fullWidth disabled={isLoading}>
                        {isLoading ? 'Salvando...' : 'Salvar Alterações'}
                    </Button>
                </DialogContent>
            </ModalDialog>
        </>
    ) : null
}

EditActionButton.propTypes = {
    params: PropTypes.shape({
        row: PropTypes.shape({
            CPFCNPJ: PropTypes.string,
            ClassificacaoSetorial: PropTypes.string,
            LinhaID: PropTypes.string,
            RazaoSocial: PropTypes.string
        }).isRequired,
        inputProps: PropTypes.object,
    }).isRequired,
    userAdmin: PropTypes.bool.isRequired,
    loadData: PropTypes.func.isRequired,
    cpfcnpjArray: PropTypes.array,
    razaoSocialArray: PropTypes.array,
    data: PropTypes.arrayOf( // Define que 'data' é uma array de objetos
        PropTypes.shape({
            id: PropTypes.number,
            CPFCNPJ: PropTypes.string,
            RazaoSocial: PropTypes.string,
            // Adicione mais campos conforme necessário
        })
    )
}

export default EditActionButton
