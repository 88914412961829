import { Button, Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import SimuladorService from "src/services/simulador-service"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_TIME_FORMAT_SHOW } from "src/utils/constants"
import { formatDateUtc } from "src/utils/date-format"
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"


const SimuladorSubordinacaoHistorico = (props) => {
    const [rows, setRows] = useState([])
    const [rowsSubordinacaoSimulado, setRowsSubordinacaoSimulado] = useState([])
    const [openModalDetailSubordinacao, setOpenModalDetailSubordinacao] = useState(false)
    const service = new SimuladorService()

    useEffect(() => {
        loadHistory()
    }, [])

    const loadHistory = () => {
        props.setLoading(true)
        service.getHistorico().then((response) => {
            const { body } = response
            const rows = body.map((obj, index) => ({ ...obj, id: index }))
            setRows(rows)
        }).catch(() => {

        }).finally(() => { props.setLoading(false) })
    }

    const openModalDetail = (params) => {
        props.setLoading(true)
        const { row } = params
        const { uuid } = row

        service.getHistoricoSimulado(uuid).then((response) => {
            const { body } = response
            const { results } = body

            const rows = results.map((obj, index) => ({ ...obj, id: index }))

            setRowsSubordinacaoSimulado(rows)
            setOpenModalDetailSubordinacao(true)
        }).catch(() => {
            props.openAlert("Erro ao abrir os dados do histórico, tente novamente...", "error")
            setOpenModalDetailSubordinacao(false)
        }).finally(() => props.setLoading(false))
    }

    const exportExcel = (params) => {
        props.setLoading(true)
        const { row } = params
        const { uuid, NomeSimulacao } = row

        service.getHistoricoSimulado(uuid).then((response) => {
            const { body } = response
            const { results } = body

            service.exportExcel(NomeSimulacao, results)
        }).catch((err) => {
            console.log(err)
            props.openAlert("Erro ao abrir os dados do histórico, tente novamente...", "error")
            setOpenModalDetailSubordinacao(false)
        }).finally(() => props.setLoading(false))
    }

    const columns = [
        {
            field: 'id',
            headerName: '#',
            minWidth: 80,
        },
        {
            field: 'NomeSimulacao',
            headerName: 'Simulação',
            flex: true,
            minWidth: 300
        },
        {
            field: 'Usuario',
            headerName: 'Usuário',
            flex: true,
            minWidth: 200,
            valueGetter: ({ value }) => value && value.Nome,
        },
        {
            field: 'CriadoEm',
            headerName: 'Criado Em',
            flex: true,
            minWidth: 200,
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => formatDateUtc(params.value, DATE_TIME_FORMAT_SHOW),
        },
        {
            field: 'Consulta',
            headerName: 'Consulta',
            flex: true,
            minWidth: 200,
            renderCell: (params) => (
                <Grid container spacing={1}>
                    <Grid item>
                        <Button variant="outlined" color={"primary"} onClick={() => openModalDetail(params)}>Abrir</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color={"success"} onClick={() => exportExcel(params)}>Excel</Button>
                    </Grid>
                </Grid>
            )
        },
    ]

    const columnsDetail = [
        {
            field: 'ID',
            headerName: '#',
            flex: 1,
        },
        {
            field: 'NomeCota',
            headerName: 'Nome da Cota',
            flex: 1,
            minWidth: 140
        },
        {
            field: 'PLCota',
            headerName: 'PL da Cota',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'IndexadorCota',
            headerName: 'Indexador',
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'TaxaAccrualCota',
            headerName: 'Taxa Accrual',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'DurationCota',
            headerName: 'Duration Cota',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'SubordinacaoContabilCota',
            headerName: 'Subordinação',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'RatingCota',
            headerName: 'Rating',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => params.value
        },
        {
            field: 'TaxaMTMCota',
            headerName: 'Taxa MTM',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'SpreadMTMCota',
            headerName: 'Spread',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'PDCota',
            headerName: 'PD',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'LGDCota',
            headerName: 'LGD',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'EADCota',
            headerName: 'EAD',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'PerdaEsperadaCota',
            headerName: 'Perda Esperada',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'CreditVaRCota',
            headerName: 'Credit VaR',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
        {
            field: 'CreditTailVarCota',
            headerName: 'Credit Tail VaR',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => formatStringToFloatDecimalCase(params.value)
        },
    ]

    return <>
        <Paper>
            <DataGrid
                autoHeight
                getRowHeight={() => '2'}
                rows={rows}
                columns={columns}
                sortingOrder={['asc', 'desc']}
                disableRowSelectionOnClick
                localeText={localeBRDataGridText}
            />
        </Paper>

        <ModalDialogPage open={openModalDetailSubordinacao}
            title="Detalhe da Subordinação"
            close={() => {
                setOpenModalDetailSubordinacao(false)
            }}>
            <>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <DataGrid
                            autoHeight
                            getRowHeight={() => '1'}
                            rows={rowsSubordinacaoSimulado}
                            columns={columnsDetail}
                            sortingOrder={['asc', 'desc']}
                            disableRowSelectionOnClick
                            localeText={localeBRDataGridText}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

SimuladorSubordinacaoHistorico.propTypes = {
    setLoading: PropTypes.function,
    openAlert: PropTypes.function,
}

export default SimuladorSubordinacaoHistorico