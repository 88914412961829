import { Button, Grid, Link, Paper, TextField, Typography, Tooltip, Chip, Divider } from "@mui/material"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import ModalDialogPage from "src/components/modal-dialog"
import FabButton from "src/components/fab-button"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { DataGrid } from "@mui/x-data-grid"
import EditableInput from "./components/editableInput"
import EditableNumberInput from "./components/editableNumberInput"
import ReportButtons from "./components/reportButtons"
import { useDropzone } from "react-dropzone"
import ImportFileService from "src/services/import-file-service"
import AlertMessage from "src/components/alert-message"
import ReceivableService from "src/services/receivable-service"
import TableFormatterService from "./components/tableFormatter"
import ProgressBar from "src/components/progress-bar"
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditableInputComponent from "src/components/editable-input-component"
import { applyRegex, onlyTextRegex } from "src/utils/strings"
import { formatTimestamp } from "src/utils/format-date"
import { formatDecimalCaseReplaceDot } from "src/utils/format-decimal"
import TextFieldFormatCurrency from "src/components/text-field-format-currency"
import BoaVistaService from "src/services/boa_vista_service"
import OnlineStatus from "src/components/online-status"

const WalletsPage = () => {
    const importFileService = new ImportFileService()
    const boaVistaService = new BoaVistaService()
    const receivableService = new ReceivableService()
    const tableFormatter = new TableFormatterService()
    const [openAddOperation, setOpenAddOperation] = useState(false)
    const [errorNomeCarteira, setErrorNomeCarteira] = useState(false)
    const [rowState, setRowState] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const [tableKey, setTableKey] = useState(Math.random())
    const [boaVistaStatus, setBoaVistaStatus] = useState({
        pf: {
            status_api: false,
            status_code: 0
        },
        pj: {
            status_api: false,
            status_code: 0
        }
    })
    const [boaVistaLoading, setBoaVistaLoading] = useState(true)

    const subordinationInitialState = [{
        id: 1,
        nomeCota: `Subordinação 1`,
        plCota: '',
        caixa: '',
        indexador: '',
        taxaAccrual: '',
        durationCota: ''
    }]
    const [subordinationsState, setSubordinationsState] = useState(subordinationInitialState)
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })
    const [nomeCarteira, setNomeCarteira] = useState('')
    const [valorPercentil, setValorPercentil] = useState(99)
    const [valorCaixa, setValorCaixa] = useState(0)

    useEffect(() => {
        setNomeCarteira("")
        loadWallets()
        loadBoaVistaStatus()
    }, [])

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }
    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setOpenAddOperation(true)
        },
        color: "primary",
    }

    const formatApiResponse = (response) => {
        const { body } = response
        const rows = body.response.map((row, index) => {
            const { Carteira, Arquivo, EstoqueLote } = row

            return {
                id: index,
                walletId: Carteira.UUID,
                stockName: Carteira.NomeCarteira,
                date: Carteira.CriadoEm,
                user: Carteira.Usuario.Nome,
                arquivo: Arquivo,
                progress: tableFormatter.progressBarWallet(Arquivo, EstoqueLote),
                status: getCurrentStatus(Carteira.Status, Arquivo.Status, EstoqueLote.Status),
                walletReports: {
                    csv: Carteira.DownloadArquivoEndpoint,
                    pdf: Carteira.DownloadRelatorioEndpoint
                },
                stockReports: {
                    csv: EstoqueLote.DownloadArquivoEndpoint,
                    pdf: EstoqueLote.DownloadRelatorioEndpoint
                }
            }
        })

        return rows
    }

    const reloadWallets = () => {
        setRowState([])
        loadWallets()
        loadBoaVistaStatus()
    }

    const loadBoaVistaStatus = () => {
        setBoaVistaLoading(true)
        boaVistaService.getApiStatus().then((response) => {
            const { body } = response
            const { pf, pj } = body

            setBoaVistaStatus({
                pf: pf,
                pj: pj
            })
            setBoaVistaLoading(false)
        }).catch(() => {
            setBoaVistaLoading(false)
        })
    }

    const loadWallets = () => {
        setLoading(true)
        receivableService.getWallets(5000, 0).then((response) => {
            const { body } = response
            setRowState(formatApiResponse(response))
            setRowCount(body.count)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })

    }

    const handleAddSubordination = () => {
        setSubordinationsState((prevState) => {
            const lastId = prevState[prevState.length - 1].id
            const nextId = lastId + 1
            return [
                ...prevState,
                {
                    id: nextId,
                    nomeCota: `Subordinação ${nextId}`,
                    plCota: '',
                    caixa: '',
                    indexador: '',
                    taxaAccrual: '',
                    percentil: '',
                    durationCota: ''
                }
            ]
        })
    }

    const handleSubordinationChange = (field, id, value) => {
        setSubordinationsState(prevState => prevState.map(subordination => {
            return subordination.id === id ? { ...subordination, [field]: formatDecimalCaseReplaceDot(value) } : subordination
        }))
    }

    const getCurrentStatus = (EstoqueStatus, ArquivoStatus, CarteiraStatus) => {
        if (ArquivoStatus !== "DONE") {
            return tableFormatter.verifyTableStatus(ArquivoStatus, "Arquivo")
        }

        if (EstoqueStatus !== "DONE") {
            return tableFormatter.verifyTableStatus(EstoqueStatus, "Estoque")
        }

        return tableFormatter.verifyTableStatus(CarteiraStatus, "Carteira")

    }

    const verifyTableStatus = (params) => {
        const { text, color } = params.value
        const { row } = params
        const { progress } = row

        return <Tooltip title={`processados ${progress.processed} de ${progress.registers}`}>
            <Chip label={text} variant="contained" color={color} />
        </Tooltip>
    }


    const progressBar = (params) => {
        const { row } = params
        const { progress } = row

        return <ProgressBar value={Number(progress.progress)} />
    }

    const updateNomeCarteira = (params, value) => {
        if (!onlyTextRegex(value)) {
            openAlert("Texto Digitado Inválido", "warning")
            return
        }

        const { walletId } = params
        setLoading(true)

        receivableService.updateWalletName(walletId, value)
            .then((response) => {
                setLoading(false)
                openAlert("Nome Carteira Alterado", "success")
                console.log(response)
            })
            .catch((err) => {
                setLoading(false)
                openAlert("Não foi possível alterar o Nome Carteira", "error")
                console.error(err)
            })
    }


    const columns = [
        {
            field: 'stockName',
            headerName: 'Nome da Carteira',
            flex: true,
            renderCell: ({ row, value }) => <EditableInputComponent
                editable={true}
                fieldValue={value}
                handleChange={(cellValue) => { updateNomeCarteira(row, cellValue) }} />,
            minWidth: 120,
            maxWidth: 300,
        },
        {
            field: 'date',
            headerName: 'Data do Cálculo',
            flex: 1,
            minWidth: 200,
            maxWidth: 200,
            renderCell: (params) => tableFormatter.formatDate(params.value)
        },
        {
            field: 'user',
            headerName: 'Usuário',
            flex: true,
            minWidth: 200,
            maxWidth: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: true,
            minWidth: 250,
            maxWidth: 250,
            renderCell: verifyTableStatus
        },
        {
            field: 'Arquivo',
            headerName: 'Progresso',
            flex: true,
            minWidth: 150,
            maxWidth: 150,
            renderCell: progressBar
        },
        {
            field: 'walletReports',
            headerName: 'Relatório Carteira',
            flex: true,
            renderCell: (params) => <ReportButtons openAlert={openAlert} disabled={params.row.status.rawStatus !== "DONE"} item={params.row} reportLinks={params.value} loading={setLoading} />,
            minWidth: 160,
            maxWidth: 160,
        },
        {
            field: 'stockReports',
            headerName: 'Relatório Estoque',
            flex: true,
            renderCell: (params) => <ReportButtons openAlert={openAlert} disabled={params.row.status.rawStatus !== "DONE"} item={params.row} reportLinks={params.value} loading={setLoading} />,
            minWidth: 160,
            maxWidth: 160,
        }
    ]

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]

        setNomeCarteira(applyRegex(file.name).slice(0, -4))
        setSelectedFile(file)
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    const handleUpload = () => {
        console.log("o valor caixa é: " + valorCaixa)
        if (selectedFile) {
            setLoading(true)
            importFileService.sendReceivableWalletFile(selectedFile, nomeCarteira, valorPercentil, subordinationsState, valorCaixa)
                .then(() => {
                    setLoading(false)
                    openAlert("Arquivo enviado com sucesso", "success")
                })
                .catch((error) => {
                    console.debug(error)
                    setLoading(false)
                    openAlert("Houve um erro ao enviar o arquivo, tente novamente.", "error")
                })
                .finally(() => {
                    setOpenAddOperation(false)
                })
        }
    }

    const handleRemoveSubordination = (id) => {
        console.log(id)

        if (subordinationsState.length < 2) return
        const list = subordinationsState.filter(item => item.id !== id)
        setSubordinationsState(list)
    }

    const closeModal = () => {
        setOpenAddOperation(false)
        setSubordinationsState(subordinationInitialState)
        setSelectedFile(null)
        setNomeCarteira("")
        setValorPercentil(99)
        setValorCaixa(0)
        setTableKey(Math.random())
    }

    return (
        <LayoutPage
            title="Histórico de carteiras"
            loading={loading}>
            <>
                <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item sx={{ marginBottom: 1 }}>
                        <Typography variant="h4">Histórico</Typography>
                        <Typography level="title-md">Chamadas de Carteiras</Typography>
                    </Grid>

                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={3}
                        >
                            <Grid item>
                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}>
                                    <Grid item>
                                        <OnlineStatus
                                            label={"Boa Vista PF"}
                                            title={`Status retornado: ${boaVistaStatus.pf.status_code}`}
                                            loading={boaVistaLoading}
                                            color={boaVistaStatus.pf.status_code === 401 ? "warning" : null}
                                            status={boaVistaStatus.pf.status_api} />
                                    </Grid>
                                    <Grid item>
                                        <OnlineStatus
                                            label={"Boa Vista PJ"}
                                            loading={boaVistaLoading}
                                            title={`Status retornado: ${boaVistaStatus.pj.status_code}`}
                                            color={boaVistaStatus.pj.status_code === 401 ? "warning" : null}
                                            status={boaVistaStatus.pj.status_api} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={() => reloadWallets()}><RefreshRoundedIcon /></Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>
                            <DataGrid
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                }}
                                autoHeight
                                getRowHeight={() => 45}
                                rows={rowState}
                                columns={columns}
                                pageSizeOptions={[25, 50, 100]}
                                rowCount={rowCount}
                                disableRowSelectionOnClick
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <ModalDialogPage
                    open={openAddOperation}
                    title="Adicionar Chamada"
                    close={() => {
                        closeModal()
                    }}
                    button={<FabButton action={actionButton} />}>
                    <Grid
                        container
                        direction="column"
                        spacing={1}>
                        <Grid item sx={{ display: 'flex' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} xl={6} >
                                    <Grid container direction={'row'} justifyContent={"center"} alignItems={"center"} spacing={0}>
                                        <Grid item>
                                            <div {...getRootProps({ className: 'dropzone' })} >
                                                <input {...getInputProps()} />
                                                <Button variant="outlined">
                                                    Arraste e solte o arquivo aqui ou clique para selecionar
                                                </Button> <br /><br />

                                            </div>
                                        </Grid>
                                        <Grid item>
                                            Modelo de arquivo para upload: <Link href="/example/ExemploEstoque.csv">Exemplo</Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {selectedFile ?
                                    <Grid item xs={12} md={6} xl={6}>
                                        <Typography variant="subtitle" gutterBottom>Nome: <b>{selectedFile.name.substring(0, 40)}</b></Typography>
                                        <Divider />
                                        <Typography variant="subtitle" gutterBottom>Data de Modificação: <b>{formatTimestamp(selectedFile.lastModified)}</b></Typography>
                                        <Divider />
                                        <Typography variant="subtitle" gutterBottom>Tamanho do Arquivo (MB): <b>{tableFormatter.getFileSize(selectedFile.size)}</b></Typography>
                                        <Divider />
                                        <Typography variant="subtitle" gutterBottom>Tipo: <b>{selectedFile.type}</b></Typography>
                                        <Divider />
                                    </Grid>
                                    : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container direction={'row'} justifyContent={"flex-start"} spacing={2}>
                        <Grid item xs={6} md={6} sm={6}>
                            <Tooltip title="Informe nome da carteira com até 100 caracteres">
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type={"text"}
                                    label="Nome da Carteira"
                                    error={errorNomeCarteira}
                                    helperText={errorNomeCarteira ? "Nome da carteira não pode estar vazio ou conter caracteres especiais" : ""}
                                    value={nomeCarteira}
                                    onChange={(event) => {
                                        const value = event.target.value
                                        if (!onlyTextRegex(value)) {
                                            setErrorNomeCarteira(true)
                                        } else {
                                            setErrorNomeCarteira(false)
                                        }
                                        setNomeCarteira(value)
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1} md={1} sm={1}>
                            <Tooltip title='Escolha um percentil de 1 a 99,99 para cálculo do CreditVar. Caso deixe em branco será considerado percentil 99.'>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type={"number"}
                                    label="Percentil"
                                    value={valorPercentil}
                                    onChange={(event) => { setValorPercentil(event.target.value) }}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2} md={2} sm={2}>
                            <Tooltip title='Informar caixa da carteira, caso tenha.'>
                                <TextFieldFormatCurrency label={"Caixa"} value={valorCaixa} onChange={setValorCaixa} focused={true} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container direction={'column'} rowSpacing={2}>
                        <Grid item width={'100%'}>
                            <DataGrid
                                key={tableKey}
                                autoHeight={false}
                                autoPageSize={false}
                                showColumnVerticalBorder
                                showCellVerticalBorder
                                hideFooter
                                rows={subordinationsState}
                                columns={[
                                    {
                                        field: 'id',
                                        headerName: '#',
                                        align: "center",
                                        disableColumnMenu: true,
                                        sortable: false,
                                        headerAlign: "center",
                                    },
                                    {
                                        field: 'nomeCota',
                                        headerName: 'Nome da Cota',
                                        description: 'Renomeie o nome das cotas caso queira',
                                        align: "center",
                                        headerAlign: "center",
                                        disableColumnMenu: true,
                                        sortable: false,
                                        flex: 1,
                                        renderCell: ({ id, value }) => <EditableInput
                                            editable={true}
                                            fieldValue={value}
                                            handleChange={(cellValue) => handleSubordinationChange('nomeCota', id, cellValue)}
                                        />,
                                    },
                                    {
                                        field: 'plCota',
                                        headerName: 'PL da Cota',
                                        description: 'Preencha o valor de patrimônio líquido de cada uma das cotas',
                                        align: "center", headerAlign: "center",
                                        disableColumnMenu: true,
                                        sortable: false,
                                        flex: 1,
                                        renderCell: ({ id, value }) => <EditableNumberInput
                                            editable={true}
                                            fieldValue={value}
                                            handleChange={(cellValue) => handleSubordinationChange('plCota', id, cellValue)}
                                        />,
                                    },
                                    {
                                        field: 'indexador',
                                        headerName: 'Indexador',
                                        description: 'Informe a taxa de carrego quando for a cota júnior ou cota única, para as demais cotas superiores a taxa accrual',
                                        align: "center", headerAlign: "center",
                                        disableColumnMenu: true,
                                        sortable: false,
                                        flex: 1,
                                        renderCell: ({ id, value }) => <EditableInput
                                            editable={true}
                                            fieldValue={value}
                                            handleChange={(cellValue) => handleSubordinationChange('indexador', id, cellValue)}
                                        />,
                                    },
                                    {
                                        field: 'taxaAccrual',
                                        headerName: 'TaxaAccrual',
                                        description: 'Informe a taxa de carrego do estoque quando for coisa júnior ou cota única, para as demais cotas informar a taxa contratada.',
                                        align: "center", headerAlign: "center",
                                        disableColumnMenu: true,
                                        sortable: false,
                                        flex: 1,
                                        renderCell: ({ id, value }) => <EditableInput
                                            editable={true}
                                            fieldValue={value}
                                            handleChange={(cellValue) => handleSubordinationChange('taxaAccrual', id, cellValue)}
                                        />,
                                    },
                                    {
                                        field: 'durationCota',
                                        headerName: 'DurationCota',
                                        description: 'Para as cotas superiores informe a duration ou prazo médio, em dias úteis, do vencimento da cota. Caso deixe em branco será considerado 252 du.',
                                        align: "center", headerAlign: "center",
                                        disableColumnMenu: true,
                                        sortable: false,
                                        flex: 1,
                                        renderCell: ({ id, value }) => <EditableInput
                                            editable={true}
                                            fieldValue={value}
                                            handleChange={(cellValue) => handleSubordinationChange('durationCota', id, cellValue)}
                                        />,
                                    },
                                    {
                                        field: 'actions',
                                        headerName: 'Excluir',
                                        align: "center", headerAlign: "center",
                                        width: 120,
                                        renderCell: (params) => (
                                            <Button onClick={() => handleRemoveSubordination(params.row.id)} color="error" align="center">
                                                <DeleteOutlineOutlinedIcon />
                                            </Button>
                                        ),
                                    }
                                ]}
                                disableRowSelectionOnClick
                            />
                        </Grid>
                        <br />
                        <Grid container justifyContent={"space-between"}>

                            <Grid item>
                                <Grid container spacing={1} justifyContent={"flex-start"}>
                                    <Grid item>
                                        <Button onClick={handleAddSubordination} variant="contained">
                                            <Tooltip title="Adicione as cotas de subordinação da sua carteira, quantas forem necessárias">
                                                + Subordinação
                                            </Tooltip>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} justifyContent={"flex-end"}>
                                    <Grid item>
                                        <Button variant="outlined" onClick={() => {
                                            closeModal()
                                        }}>Cancelar</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => {
                                            handleUpload()
                                        }}>Calcular</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </ModalDialogPage>

            </>
        </LayoutPage>
    )

}


export default WalletsPage
