import { Button, Chip, Divider, Grid, Paper, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import { useDropzone } from "react-dropzone"
import { formatDecimalCase } from "src/utils/format-decimal"
import { formatTimestamp, formateDateTimeString } from "src/utils/format-date"
import AlertMessage from "src/components/alert-message"
import SelectValue from "src/components/select-value"
import DeleteDataService from "src/services/delete-data-service"
import ImportFileService from "src/services/import-file-service"
import { IntegrationService } from "src/services/integration-service"
import SkeletonTable from "src/components/skeleton-table"
import { DataGrid } from "@mui/x-data-grid"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { DELETE_TYPE } from "src/utils/constants"
import ProgressBar from "src/components/progress-bar"
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'


const DeleteDataPage = () => {
    const deleteDataService = new DeleteDataService()
    const importFileService = new ImportFileService()
    const integrationService = new IntegrationService()
    const [loading, setLoading] = useState(false)
    const [app, setApp] = useState("")
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })
    const [importType, setImportType] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)
    const [logList, setLogList] = useState([])
    const [loadTable, setLoadTable] = useState(false)
    const options = deleteDataService.getAPPList()

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        setSelectedFile(file)
        setImportType(file.name.split(".")[0])

    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    useEffect(() => {
        loadLastDeleted()
    }, [])

    const loadLastDeleted = (limit = 100) => {
        setLoadTable(true)
        importFileService.loadLastImports(limit, DELETE_TYPE)
            .then((response) => {
                const { body } = response
                const { results } = body
                const rows = results.map((obj, index) => ({ ...obj, id: index }))
                setLogList(rows)
                setLoadTable(false)
            })
            .catch(() => {
                setLoadTable(false)
            })
    }

    const handleUpload = () => {
        if (selectedFile) {
            setLoading(true)
            deleteDataService.sendDataLakeFile(selectedFile, importType, app, false)
                .then(() => {
                    setLoading(false)
                    openAlert("Arquivo enviado com sucesso", "success")
                })
                .catch((error) => {
                    console.debug(error)
                    setLoading(false)
                    openAlert("Houve um erro ao deletar os dados, tente novamente.", "error")
                })
        }
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }
    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const getFileSize = (size) => {
        const mbSize = size / (1024 * 1024)
        return parseFloat(mbSize).toFixed(2)
    }

    const verifyTableStatus = (params) => {
        const { value } = params
        const { text, color } = integrationService.verifyTableStatusWorkflow(value)

        return <Chip label={text} variant="contained" color={color} />
    }

    const formatModel = (params) => {
        if (params.value === null) {
            return ""
        }
        return params.value.modelo
    }

    const formatDate = (params) => {
        if (params.value === null) {
            return ""
        }
        return formateDateTimeString(params.value)
    }

    const progressBar = (params) => {
        const { row, value } = params
        const { total_registros } = row
        const valueBar = (value / total_registros) * 100
        let formatedValue = formatDecimalCase(valueBar)

        if (value < 0.1) {
            formatedValue = 0
        }

        return <ProgressBar value={Number(formatedValue)} />
    }

    const columns = [
        {
            field: 'metadata_input',
            headerName: 'Modelo',
            flex: true,
            renderCell: formatModel
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: true,
            renderCell: verifyTableStatus
        },
        // {
        //     field: 'tamanho_arquivo_bytes',
        //     headerName: 'Tamanho do Arquivo (bytes)',
        //     flex: true,
        // },
        {
            field: 'total_registros',
            headerName: 'Total de Registros',
            flex: 1,
        },
        {
            field: 'total_registros_processados',
            headerName: 'Progresso',
            flex: true,
            renderCell: (params) => progressBar(params)
        },
        // {
        //     field: 'nome_usuario',
        //     headerName: 'Usuário',
        //     flex: true,
        // },
        {
            field: 'criado_em',
            headerName: 'Data de Criação',
            flex: true,
            renderCell: (params) => formatDate(params)
        },
        {
            field: 'atualizado_em',
            headerName: 'Data de Atualização',
            flex: true,
            renderCell: (params) => formatDate(params)
        },
    ]

    return (
        <LayoutPage title="Exclusão de Dados" loading={loading}>
            <div>
                <Box sx={{ mx: 2, width: 'auto' }}>
                    <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />

                    <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <SelectValue
                                label={"Aplicação"}
                                value={app}
                                id="select-app"
                                changeValue={setApp}
                                options={options} />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <TextField value={importType} onChange={(event) => setImportType(event.target.value)} fullWidth variant="standard" focused label="Modelo de Arquivo para excluir dados." />
                        </Grid>
                    </Grid>
                    <br />
                    {/* <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item sm={12} md={6} xl={4} xs={12}> */}

                    <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>

                        <Grid item xs={12} md={6} sm={12} xl={4}>
                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                <Grid item>
                                    <div {...getRootProps({ className: 'dropzone' })} style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                                        <input {...getInputProps()} />
                                        <Button variant="outlined" component="span">
                                            Arraste e solte o arquivo aqui ou clique para selecionar
                                        </Button>
                                        <p>{selectedFile ? selectedFile.name : "Nenhum arquivo selecionado"}</p>
                                    </div>
                                </Grid>
                                <Grid item >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUpload}
                                        disabled={!selectedFile}>
                                        Deletar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {selectedFile ?
                            <Grid item xs={12} md={4} xl={3}>
                                <Typography variant="subtitle" gutterBottom>Nome: <b>{selectedFile.name}</b></Typography>
                                <Divider />
                                <Typography variant="subtitle" gutterBottom>Data de Modificação: <b>{formatTimestamp(selectedFile.lastModified)}</b></Typography>
                                <Divider />
                                <Typography variant="subtitle" gutterBottom>Tamanho do Arquivo (MB): <b>{getFileSize(selectedFile.size)}</b></Typography>
                                <Divider />
                                <Typography variant="subtitle" gutterBottom>Tipo: <b>{selectedFile.type}</b></Typography>
                                <Divider />
                            </Grid>
                            : null}
                    </Grid>
                </Box>

                <br />
                <br />
                <Grid container direction="row" justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <Typography variant={"h5"}>Últimas Importações</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={() => loadLastDeleted()}><RefreshRoundedIcon /></Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="left" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        {loadTable ? <SkeletonTable /> :
                            <Paper>
                                <DataGrid
                                    autoHeight
                                    getRowHeight={() => 45}
                                    rows={logList}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    disableRowSelectionOnClick
                                    sortingOrder={['asc', 'desc']}
                                    localeText={localeBRDataGridText}
                                />
                            </Paper>}
                    </Grid>
                </Grid>
            </div>
        </LayoutPage>
    )
}

export default DeleteDataPage
