import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TableEventosFluxo from "../table/table_eventos_fluxo"
import PriceMemoryCalc from "../../prices/characteristcs/table-memory/price-memory-calc"
import PropTypes from 'prop-types'
import CharacteristicsService from "src/services/characteristics-service"
import { DATE_FORMAT } from "src/utils/constants"
import dayjs from "dayjs"
import Loading from "src/components/loading"
import EventosFluxoService from "src/services/eventos_fluxo_service"
import { ordenaPorData } from "src/utils/date-format"
import SkeletonTable from "src/components/skeleton-table"


const FluxoEventosTab = ({ value }) => {
    const service = new CharacteristicsService()
    const eventosFluxoService = new EventosFluxoService()
    const [data, setData] = useState(null)
    const [expanded, setExpanded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [eventosFluxo, setEventosFluxo] = useState([])
    const [loadingEventosFluxo, setLoadingEventosFluxo] = useState(false)

    useEffect(() => {
        if (value && value.length > 4) {
            loadDataMaisRecente()
            loadEventosFluxo()
        }
    }, [value])

    const loadDataMaisRecente = async () => {
        setLoading(true)
        try {
            // Chama o serviço para obter a data mais recente
            const response = await service.getDataMaisRecente(value)
            const { body } = response
            const { DataMaisRecente } = body

            // Aguarda o carregamento dos dados com a data mais recente
            await loadData(dayjs(DataMaisRecente[0]).format(DATE_FORMAT))
        } catch (error) {
            // Em caso de erro, usa a data atual
            const dataMaisRecente = dayjs().format(DATE_FORMAT)
            await loadData(dataMaisRecente)
        }
    }

    const loadEventosFluxo = () => {
        setLoadingEventosFluxo(true)
        const query = `?cetip__exact=${value}`

        eventosFluxoService.getFincsEventos(query)
            .then((response) => {
                const { body } = response
                const { results } = body
                const data = ordenaPorData(results, "data_evento", "asc")
                const eventos = data.reduce((result, item) => {
                    const fluxoBaseKey = `fluxoBase${item.fluxo_base}`
                    if (!result[fluxoBaseKey]) {
                        result[fluxoBaseKey] = {
                            description: `Fluxo Base ${item.fluxo_base}`,
                            data: []
                        }
                    }
                    result[fluxoBaseKey].data.push(item)
                    return result
                }, {})
                setEventosFluxo(eventos)
            })
            .finally(() => {
                setLoadingEventosFluxo(false)
            })
    }

    const toggleAccordion = () => {
        setExpanded(!expanded)
    }

    const loadData = async (data_referencia) => {
        setLoading(true)
        try {
            // Faz a chamada para obter os parâmetros de cálculo
            const response = await service.getParametersCalc(value, data_referencia)
            const { body } = response

            // Verifica o tipo da resposta antes de atualizar os dados
            if (typeof body !== 'string') {
                setData(body[0])

                if (!expanded) {
                    toggleAccordion()
                }
            }
        } finally {
            setLoading(false)
        }
    }

    return <>
        <Loading show={loading} />
        <Grid
            container
            direction="row"
            alignContent="flex-start"
            justifyContent="flex-start"
            spacing={0}
            sx={{
                padding: 2,
                height: "100%", // altura de 100% da Box
            }}>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                <Accordion expanded={expanded} onChange={(e, isExpanded) => setExpanded(isExpanded)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Parâmetros para Cálculo de Preços</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceMemoryCalc data={data} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Eventos do Fluxo</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {loadingEventosFluxo ? <SkeletonTable /> : <TableEventosFluxo data={eventosFluxo} />}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    </>
}

FluxoEventosTab.propTypes = {
    value: PropTypes.string,
}

export default FluxoEventosTab