import { Button, Grid, TextField } from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import PropTypes from 'prop-types'
import SkeletonLine from "src/components/skeleton-line"
import { ordenaPorDataReferencia } from "src/utils/date-format"
import ParameterTable from "src/components/table/parameter-table"
import { calculateMinWidth, renderCellDate, renderTextColumn } from "src/utils/table-render-functions"

const tableDefault = [
    {
        cells: ["Razão Social: ", "CNPJ: ", "Grupo Econômico: "]
    }
]

const UltimoEventoCreditoDevedor = ({ admin, cpfCnpj, dadosEmpresa, loading, value }) => {
    const ultimoEventoCreditoDevedorDefault = { data_referencia: "", evento_credito_devedor: "", score_evento: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoEventoCreditoDevedor, setUltimoEventoCreditoDevedor] = useState(ultimoEventoCreditoDevedorDefault)
    const [loadingCreditoDevedor, setLoadingCreditoDevedor] = useState(false)
    const [table, setTable] = useState(tableDefault)
    const [rows, setRows] = useState([])

    useEffect(() => {
        console.log("UltimoEventoCreditoDevedor: ", cpfCnpj)
        if (validateValue()) loadData(cpfCnpj)
    }, [cpfCnpj])

    const validateValue = () => {
        if (cpfCnpj) {
            return true
        }

        return false
    }

    const loadData = () => {
        setLoadingCreditoDevedor(true)
        emissaoService.getUltimoEventoCreditoDevedor(cpfCnpj).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataReferencia, EventoCreditoDevedor, ScoreEvento } = data

                setUltimoEventoCreditoDevedor({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    evento_credito_devedor: EventoCreditoDevedor,
                    score_evento: ScoreEvento,
                })
            } else {
                setUltimoEventoCreditoDevedor(ultimoEventoCreditoDevedorDefault)
            }
        }).catch(() => {
            setUltimoEventoCreditoDevedor(ultimoEventoCreditoDevedorDefault)
        }).finally(() => {
            setLoadingCreditoDevedor(false)
        })
    }

    const buildTable = () => {
        setTable([
            {
                cells: [`Razão Social: ${dadosEmpresa.devedor}`, `CNPJ: ${dadosEmpresa.cpfCnpj}`, `Grupo Econômico: ${dadosEmpresa.grupoEconomico}`]
            }
        ])
    }

    const openEventlCreditoDevedor = () => {
        if (!validateValue()) {
            setOpenMostrarTodos(true)
            return
        }

        buildTable()
        loading(true)
        emissaoService.eventoCreditoDevedor("get", `?CPFCNPJ=${cpfCnpj.toUpperCase()}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body
                const rows = ordenaPorDataReferencia(results, "desc").map((obj, index) => ({ ...obj, id: index }))
                setRows(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = async (row) => {
        const { ID } = row

        loading(true)
        emissaoService.eventoCreditoDevedor("delete", ID)
            .then(() => { openEventlCreditoDevedor() })
            .finally(() => {
                loading(false)
            })
    }

    const addRow = async () => {
        let lastItem = rows[0]

        if (rows.length === 0) {
            lastItem = {
                "DataReferencia": "2023-06-14",
                "DataValidade": "2024-06-14",
                "CETIP": value,
                "CPFCNPJ": cpfCnpj
            }
        }

        loading(true)
        emissaoService.eventoCreditoDevedor("post", lastItem)
            .then(() => {
                openEventlCreditoDevedor()
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const updateRow = async (newRow) => {
        const { ID } = newRow
        console.log(newRow)
        loading(true)
        emissaoService.eventoCreditoDevedor("patch", ID, newRow)
            .finally(() => {
                loading(false)
            })

        return newRow
    }

    const minWidthEvento = calculateMinWidth(rows, "EventoCreditoDevedor")

    return <>
        {loadingCreditoDevedor ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Referência" value={ultimoEventoCreditoDevedor.data_referencia} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Evento Crédito" value={ultimoEventoCreditoDevedor.evento_credito_devedor} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Score Evento" value={ultimoEventoCreditoDevedor.score_evento} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openEventlCreditoDevedor}>Mostrar Todos</Button>
                </Grid>
            </Grid>}

        <ModalDialogPage open={openMostrarTodos}
            title="Último Evento de Crédito Devedor"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <ParameterTable
                            columns={[
                                { field: 'DataReferencia', headerName: 'Data Referencia', flex: 1, editable: admin, renderCell: renderCellDate },
                                { field: 'EventoCreditoDevedor', headerName: 'Evento', flex: 1, minWidth: minWidthEvento, editable: admin, renderCell: renderTextColumn },
                                { field: 'ScoreEvento', headerName: 'Score Evento', flex: 1, editable: admin, },
                            ]}
                            rowHeigth="auto"
                            data={rows}
                            setData={setRows}
                            createRow={addRow}
                            deleteRow={deleteItem}
                            updateRow={updateRow}
                            loadData={loadData}
                            disableDeleteColumn={!admin}
                            disableAddRow={!admin}
                            buttonAddVariant={"contained"}
                            buttonAddTitle={"Adicionar Rating"}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoEventoCreditoDevedor.propTypes = {
    admin: PropTypes.bool,
    cpfCnpj: PropTypes.string,
    dadosEmpresa: PropTypes.object,
    loading: PropTypes.func,
    value: PropTypes.string,
}


export default UltimoEventoCreditoDevedor